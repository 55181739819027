@import "../../../../lib/style/variables";

.dimensions-editor-container {
  display: flex;
  flex-wrap: wrap;

  .admin-editor-editable-dimension {
    border: 1px solid #eee;
    padding: $size-sm;
    margin: $size-sm;
    &.editing {
      border-color: $infostat-interface-blue;
    }

    .main {
      display: flex;
      .grow {
        flex-grow: 1;
      }
    }
  }
}

.admin-editor-editable-dimension {
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: $size-md;
  }
  .sort-order {
    display: inline-block;
    min-width: 1.2em;
    color: $text-color-subdued;
  }

  .overflow-container {
    max-height: 600px;
    overflow-y: auto;
  }

  &.editing .draggable {
    display: flex;
    .main {
      flex-grow: 1;
      .drag-handle {
        padding-left: $size-md;
      }
      padding: $size-xs $size-sm;
      display: flex;
      justify-content: space-between;
      border: 1px solid $grey6;
    }
    &:not(:last-child) {
      margin-bottom: $size-sm;
    }
  }
  table {
    border-collapse: collapse;
    tr.bottom {
      border-top: 1px solid #ddd;
    }
    td:not(:first-child) {
      padding-left: 5px;
      min-width: 80px;
    }
    td.draft {
      padding-left: 5px;
      padding-right: 5px;
      min-width: 1px;
    }
  }

  .droppable-unsorted,
  .droppable-fixed-bottom,
  .droppable-fixed-top {
    min-height: 50px;
    border: 1px solid #eee;
  }
}
