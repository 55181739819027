@import "../lib/style/variables";

.foldout-panel {
  margin-top: map-get($std-sizes, "md");
  &.xs,
  &.sm {
    margin-top: 0;
  }
  &.md {
    margin-top: $size-xs;
  }

  & > .header {
    display: flex;
    align-items: center;
    h2,
    h3,
    h4 {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
    }

    & > .toggle-icon {
      &.xs,
      &.sm {
        margin-right: $size-xs;
      }
      &.md,
      &.lg,
      &.xl {
        margin-right: $size-xs;
      }
      .bp4-icon {
        display: inline-block;
        padding-top: 0.3em;
      }
    }
  }

  &.is-open > .header {
    margin-bottom: $size-md;
    &.xs,
    &.sm {
      margin-bottom: $size-xs;
    }
    &.md {
      margin-bottom: $size-xs;
    }
  }
}
