@import "../../lib/style/variables";

.infostat-editable-input-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .field-input {
    flex-grow: 1;
    margin-right: $size-lg;
  }
}

.infostat-editable-input-range {
  .fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .field-input {
      flex-grow: 1;
      margin-right: $size-lg;
    }
  }
}
