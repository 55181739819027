@import "../../../../../lib/style/variables";

.admin-measure-metadata {
  min-height: 800px;
  .section {
    margin-bottom: $size-md;
  }
  .editable-field {
    display: flex;
    align-items: flex-end;

    & > div {
      flex-grow: 1;
    }

    &.subject-path,
    &.org-access {
      border: none;
      .label {
        margin-top: $size-sm;
        padding-bottom: $size-sm;
        color: $grey6;
        font-weight: bold;
      }
    }
  }

  .tags-editor {
    .label {
      font-weight: bold;
      margin-bottom: $size-sm;
    }
    .existing-tags {
      flex-wrap: wrap;
      display: flex;
      margin-bottom: $size-sm;
    }
    .tag {
      &:not(:last-child) {
        margin-right: $size-sm;
      }
      margin-top: $size-sm;
      font-weight: bold;
      border-radius: $border-radius-sm;
      background-color: lighten($infostat-interface-blue, 20);
      &.unsaved {
        background-color: $main-bg-color;
      }
      font-size: 12px;
      display: flex;
      align-items: center;
      .name {
        padding: 0 $size-sm;
      }
      .button {
        border-radius: $border-radius-sm;
        padding: 0 $size-xs;
        border-left: 1px solid $grey5;
        &:hover {
          cursor: pointer;
          background-color: lighten(red, 20);
        }
      }
    }
  }

  .new-answer-value-form .fields {
    display: flex;
    & > *:not(:last-child) {
      margin-right: $size-sm;
    }
  }
  .survey-values-editor {
    background-color: $light-grey3;
    border-radius: 4px;
    padding: $size-sm $size-md;
  }
  .answer-value-item {
    .valid-dates {
      display: flex;
      & > *:not(:last-child) {
        margin-right: $size-sm;
      }
    }
  }
  .answer-value-item:not(.editing) {
    display: flex;
    margin-bottom: $size-sm;
    > div:first-child {
      flex-grow: 1;
      max-width: 300px;
    }
  }
}
