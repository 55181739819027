@import "../../../../../lib/style/variables";

.card-social-share {
  .share-buttons {
    display: flex;
    align-items: center;
    & > div {
      &:hover {
        cursor: pointer;
      }
      margin-right: $size-sm;
    }
  }
}
