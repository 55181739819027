@import "../../../../lib/style/variables";

.overview-table-latest {
  &.table-container {
    max-height: 300px;
    overflow: auto;
  }

  table .data-row > td.date {
    white-space: nowrap;
    font-size: 12px;
    color: $text-color-subdued;
  }

  table.infostat-table {
    td {
      padding-top: $size-sm;
      padding-bottom: $size-sm;
    }
    td:first-child {
      padding-left: 0;
    }
  }

  p {
    margin: 0;
  }

  p.line-header {
    font-size: 14px;
    font-weight: bold;
  }
}
