@import "../lib/style/variables";

.error-boundary-container {
  max-width: $read-only-chart-card-max-width;
  margin: $size-md auto;
  padding: $size-md $size-lg;
  border-radius: $border-radius-lg;
  background-color: $warning-yellow-bg;
  .header-block {
    display: flex;
    justify-content: space-between;
  }
}
