@import "../../../../../lib/style/variables";

#adjust-font-sizes-dialog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  #dropdowns {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    overflow: hidden;
    .ms-Dropdown-container {
      &:not(:last-child) {
        margin-right: $size-md;
      }

      flex-basis: 30%;
      flex-grow: 1;
      max-width: 200px;
    }
  }

  .preview {
    overflow: auto;
    flex-grow: 1;
  }
}
