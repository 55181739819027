@import "../../../../lib/style/variables";

#create-measure {
  h2 {
    margin-bottom: $size-sm;
  }

  .breakdown-field {
    display: flex;
    align-items: flex-end;
    & > *:not(:last-child) {
      margin-right: $size-md;
    }
  }

  .step-footer {
    display: flex;
    justify-content: space-between;
  }

  .infostat-data-warning {
    background-color: $infostat-interface-blue;
    h2 {
      padding: $size-sm;
      color: white;
      text-align: center;
    }
    h2:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .select-measure-type {
    .big-buttons {
      .button-container {
        .left-block {
          .left-emoji {
            margin-right: $size-sm;
          }
          display: flex;
        }
        &:not(.disabled):hover {
          cursor: pointer;
          background-color: lighten($infostat-interface-blue, 10);
        }
        background-color: $infostat-interface-blue;
        color: white;
        border-radius: $border-radius-sm;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $size-sm;
        &:not(:last-child) {
          margin-bottom: $size-lg;
        }
        > :last-child {
          justify-self: flex-end;
        }
        svg path {
          fill: white;
        }

        &.disabled {
          background-color: $grey6;
        }
      }
    }
  }
}
