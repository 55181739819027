@import "../../../../../../lib/style/variables";

.filter-measures-container {
  padding: $size-sm $size-md;
  margin: $size-md $size-lg;
  border-radius: $border-radius-sm;
  background-color: $infostat-interface-blue-bg;

  .filter-measures-list {
    padding: $size-sm $size-sm;
  }

  .filter-divider {
    padding: $size-md $size-md;
    path {
      fill: $grey5;
    }
  }

  .single-filter-container {
    display: flex;
    justify-content: space-between;

    .single-filter-measure {
      padding: 0 0;
      .item {
        display: inline-block;
        width: $text-input-narrow-width;
        margin-right: $size-md;
      }

      .number-input + .number-input {
        margin-left: $size-xs;
      }
    }
  }
}
