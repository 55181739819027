@import "../../../../../lib/style/variables";

$card-label-size: 14px;
$data-frame-border: 1px solid $grey6;

$stats-geo-and-output-breakpoint-width: 700px;

$fluent-ui-input-label-height: 26px;

@mixin toolbar-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 1.6px 3.6px 0px,
    rgba(0, 0, 0, 0.21) 0px 0.3px 0.9px 0px;
}

@mixin data-selections {
  .data-selections {
    display: flex;
    padding-left: $size-lg;
    .search-block {
      margin-top: $fluent-ui-input-label-height;
      margin-right: $size-sm;
      margin-left: $size-xs;
      .search {
        margin-top: 0.5em;
      }
    }
  }

  // TODO: better height/width handling!
  // Currently, sizes are set so that mobile screens will get scrolled containers
  .data-selection {
    h3 {
      margin-bottom: $size-sm;
    }
    display: block;
    .item {
      display: inline-block;
      width: $text-input-default-width;
      margin-right: $size-md;
    }

    .breakdowns-container {
      display: flex;
      flex-wrap: wrap;
    }

    .measure-selection-container {
      display: inline-flex;
      .alerts-inactive,
      .alerts-active {
        svg path {
          fill: $folder-orange;
          stroke: black;
        }
        svg {
          filter: drop-shadow(0px 0px 4px $grey4);
        }
      }

      .infostat-fluent-icon {
        padding-top: 17px; // Depends on Fluent UI label size
      }

      .measure-regular-breakdown-multi {
        justify-self: flex-start;
        align-self: flex-start;
      }
    }
  }
}

@mixin common-card-content-styles {
  // We don't apply the max-width to micro-output-container since we
  // maps to be able to take up all available card space
  .stats-geo-and-output-container {
    background-color: inherit;
    @media screen {
      display: flex;
      overflow: auto;
      &.read-only {
        max-width: $read-only-chart-card-max-width;
      }
    }
    @media screen and (max-width: $stats-geo-and-output-breakpoint-width) {
      flex-direction: column;
    }
    .data-geo {
      flex-shrink: 0;
      width: 270px;
      overflow: auto;
    }
  }

  .stats-geo-and-output-container,
  .micro-output-container {
    // SVG print styles
    @media print {
      .chart-guide-line {
        stroke: grey !important;
      }
    }

    // Toolbar styles
    .pivot-menu .bp4-icon svg {
      fill: $soft-black;
    }

    .tab-toolbar {
      padding: $size-sm;
      display: flex;
      justify-content: space-between;
      .menus-left,
      .menus-right {
        display: flex;
      }
      .menu {
        margin-right: $size-lg;
        &:hover {
          cursor: pointer;
        }
        font-size: 14px;
        .icon {
          margin-right: $size-sm;
          vertical-align: middle;
        }
        .label {
          display: inline-block;
          margin-right: $size-xs;
          vertical-align: top;
          @media (max-width: 500px) {
            display: none;
          }
        }
      }
    }
  }

  .menu-container {
    z-index: 10; // Avoid have box-shadow from toolbar spill over on this surface
    @include toolbar-box-shadow;
  }
  .menu-container {
    @include toolbar-box-shadow;
  }

  $font-size-table-cells: 12px;
  $sub-header-bottom-margin: 2px;
  $sub-header-std-size: 12px;

  &.editing .tab-content-with-toolbar {
    flex-grow: 1;
  }

  &.read-only .svg-chart-container {
    margin: auto;
  }

  .dataset-settings {
    .setting-checkbox {
      margin-bottom: 5px;
    }
  }

  &.editing .stats-output-viewer {
    border-top: $data-frame-border;
    border-left: $data-frame-border;
    min-width: 400px;
    min-height: 700px;
  }

  .stats-output-viewer,
  .micro-output-viewer {
    flex-grow: 1;
    h2 {
      font-family: "Circular-Medium", sans-serif;
    }
    h3,
    h4,
    th,
    table,
    p,
    details {
      font-family: "Circular-Regular";
    }

    @media screen {
      // background-color: white;
      background-color: inherit;
      overflow: auto;
    }
    @media print {
      break-inside: avoid;
    }
    display: flex;
    flex-direction: column;
    border-radius: 0 0 $border-radius-sm 0; // Corner has to correspond to Card border radius

    .tab-content-with-toolbar {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      @media screen {
        display: block;
      }

      .tab-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        @media screen {
          overflow-y: auto;
          padding: $size-md $size-sm;
          padding-right: $size-md;
        }
        @media print {
          max-height: auto;
        }
      }

      .micro-info-tab.tab-content,
      .dataset-info.tab-content,
      .map-tab.tab-content {
        padding: $size-lg $size-lg;
        max-width: calc(100% - #{$size-lg * 2});
      }

      .dataset-tables.tab-content,
      .infostat-survey-string-table.tab-content {
        td.tabular-data {
          font-feature-settings: "tnum";
        }

        td,
        th {
          font-size: $font-size-table-cells;
        }
        td.date,
        td.survey,
        td.decimal,
        td.integer {
          font-family: "Circular-Mono";
          font-feature-settings: "zero" on;
        }
        margin: auto;

        /**
          * Note that these settings are defined in both CSS and JS (in order to construct well-sized SVG charts) so changes to these must be made in multiple files:
          * src/lib/application/charts/shared/core/definitions.ts
          * src/lib/application/charts/shared/texts.ts
          * src/views/stats/data_card/DataCard.scss
          */
        h2 {
          font-size: 18px;
          margin-bottom: 4px;
        }
        h3 {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: $sub-header-bottom-margin;
        }
        .header-section {
          margin-top: 8px;
        }
        h4 {
          &.subheader {
            font-weight: normal;
            margin-bottom: $sub-header-bottom-margin;
            margin-top: 0px;
            font-size: $sub-header-std-size;
            &.grouping {
              margin-top: 4px;
            }
          }
          &.region-and-time {
            font-weight: normal;
            margin-bottom: $sub-header-bottom-margin;
            margin-top: 4px;
            font-size: $sub-header-std-size;
          }
        }

        p.unit {
          font-size: $sub-header-std-size;
        }
        p.source {
          font-size: $sub-header-std-size;
        }
        .dataset-table-container {
          margin: $size-sm;
          margin-left: auto;
          margin-right: auto;
          &.table-padding-small {
            padding: 0 $size-md;
          }
          &.table-padding-medium {
            padding: 0 $size-lg;
          }
          &.table-padding-large {
            padding: 0 $size-xl;
          }
        }
      }
    }
  }

  .micro-output-viewer.map-output {
    // Avoid scrollbar as artifacts from mapbox element sizing
    overflow: hidden;
  }

  .micro-output-container {
    @media screen {
      overflow: hidden;
    }
    .tab-content-with-toolbar .tab-content {
      @media screen {
        overflow-y: hidden;
      }
    }
  }
}

@mixin common-card-container-styles {
  .card-error-bar {
    padding: $size-md $size-lg;
  }

  .card-tab-content {
    display: flex;
    align-items: center;
    .actions-container {
      display: flex;
      flex-grow: 1;
      text-align: right;
      & > div:not(:last-child) {
        margin-right: $size-md;
      }
    }
    .menu-button {
      &.subtle path {
        fill: $soft-black;
      }
      padding-top: $size-xs;
      i:hover {
        cursor: pointer;
      }
    }

    .card-title-input .ms-TextField-fieldGroup {
      border-color: $grey7;
      input {
        font-size: $card-label-size;
      }
    }
    .card-label {
      font-size: $card-label-size;
      display: flex;
      align-items: center;
      padding: $size-sm 0;
      padding-right: $size-md;
    }
  }
}
