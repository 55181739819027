@import "../../../../lib/style/variables";
@import "./card_general/card_container_shared";

$map-loading-overlay-color: rgba($grey8, 0.7);
$map-disabled-overlay-color: rgba($grey8, 0.6);
$stripes-band-width: 2px;
$stripes-background: repeating-linear-gradient(
  -45deg,
  $map-disabled-overlay-color,
  $map-disabled-overlay-color $stripes-band-width,
  darken($map-disabled-overlay-color, 10) $stripes-band-width,
  darken($map-disabled-overlay-color, 10) $stripes-band-width * 2
);

$border-style: 1px solid $grey6;
.card-placeholder {
  border: $border-style;
  margin-top: $size-lg;
  margin-bottom: $size-sm;
  background: $stripes-background;
}

.card-data-initializing {
  height: 400px;
}

/**
 * Styles applying to multiple types of cards
 */
.document-card-container {
  margin-left: auto;
  margin-right: auto;

  .time-select {
    display: flex;
    .infostat-slider {
      flex-grow: 1;
    }
    align-items: center;
    margin-left: $size-md;

    @media screen and (max-width: 500px) {
      display: block;
    }
  }

  @media screen {
    &.edit-mode-enabled:not(:first-child) {
      margin-top: $size-lg;
    }
  }

  @media print {
    break-inside: avoid-page;
    &:not(:last-child) {
      margin-bottom: 1cm;
    }
  }

  /** Animation **/
  @media screen {
    transition: opacity 0.2s ease-out, height 0.4s ease-in;

    opacity: 1;

    &.is-removing {
      opacity: 0;
    }
  }

  @include common-card-container-styles;
}
