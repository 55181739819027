@import "../style/variables";

.clickable:hover {
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.full-content-margin {
  margin: map-get($std-sizes, "lg");
}

.content-default {
  margin: auto;
  max-width: $content-max-width;
}

.content-padding-right {
  padding-right: map-get($std-sizes, "lg");
}

.content-padding {
  padding: 0 map-get($std-sizes, "lg");
}

.content-padding-default {
  padding: $size-sm $size-lg;
  padding-bottom: $size-lg;
}

@each $l, $s in $std-sizes {
  .margin-#{$l} {
    margin: $s;
  }
  .padding-#{$l} {
    padding: $s;
  }
  .padding-y-#{$l} {
    padding-top: $s;
    padding-bottom: $s;
  }
  .padding-x-#{$l} {
    padding-right: $s;
    padding-left: $s;
  }
  .padding-top-#{$l} {
    padding-top: $s;
  }
  .padding-bottom-#{$l} {
    padding-bottom: $s;
  }
  .margin-y-#{$l} {
    margin-top: $s;
    margin-bottom: $s;
  }
  .margin-x-#{$l} {
    margin-left: $s;
    margin-right: $s;
  }
  .margin-left-#{$l} {
    margin-left: $s;
  }
  .margin-right-#{$l} {
    margin-right: $s;
  }
  .margin-top-#{$l} {
    margin-top: $s;
  }
  .margin-bottom-#{$l} {
    margin-bottom: $s;
  }
}

.section,
section {
  margin-bottom: map-get($std-sizes, "lg");
}

.display-none {
  display: none;
}

.tools-surface {
  background-color: #fafbfb;
}

p.compact {
  margin-top: 0;
  margin-bottom: $size-xs;
}

.transparent {
  opacity: 0;
}

.flex-grow {
  flex-grow: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-end {
  align-items: flex-end;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

// input
.input-default-width {
  width: $text-input-default-width;
}

// TEXT

.text-subdued {
  color: $text-color-subdued;
}

.no-text-select {
  user-select: none;
}

// Mobile
.mobile-only {
  @media (min-width: ($breakpoint-mobile + 1)) {
    display: none !important;
  }
}

.hide-on-mobile {
  @media (max-width: $breakpoint-mobile) {
    display: none !important;
  }
}

.big-screen-only {
  @media (max-width: ($breakpoint-big-screen)) {
    display: none !important;
  }
}
.hide-on-big-screen {
  @media (min-width: ($breakpoint-big-screen + 1)) {
    display: none !important;
  }
}

.font-red {
  color: red;
}

.bg-white {
  background-color: white;
}

.monospace {
  font-family: monospace;
}

.no-margin {
  margin: 0;
}
.no-margin-important {
  margin: 0 !important;
}