.striped-bar {
  $color: rgb(233, 233, 134);
  $lighten-factor: 15;
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  background: linear-gradient(
    45deg,
    $color 25%,
    lighten($color, $lighten-factor) 25%,
    lighten($color, $lighten-factor) 50%,
    $color 50%,
    $color 75%,
    lighten($color, $lighten-factor) 75%,
    lighten($color, $lighten-factor)
  );
  background-size: 40px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-text {
  font-size: 16px;
  color: #333;
}
