@import "../../../../../../lib/style/variables";

$selected-color: lighten($infostat-interface-blue, 25);
$hover-color: lighten($infostat-interface-blue, 35);

.micro-tool-panel {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: $border-radius-sm;

  .tools {
    section:first-child {
      margin-right: auto;
    }
    section:last-child {
      margin-left: auto;
    }
    padding: $size-xs;
    // border-top: 1px solid $light-grey2;
    // padding-top: $size-sm;
    display: flex;
  }
  section {
    margin-bottom: 0;
  }

  button.selected,
  button:active {
    background-color: $selected-color;
  }

  button.ms-Button:not(:disabled):not(:active):hover {
    background-color: $hover-color;
  }

  .transparency-slider {
    min-width: 250px;
    padding-top: $size-xs;
    border-top: 1px solid $light-grey2;
    display: flex;
    align-items: center;
    .label-left {
      padding: 0 $size-md;
    }
    .slider {
      flex-grow: 1;
    }
  }
}
