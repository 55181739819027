@import "../lib/style/variables";

$sizes: "xs", "sm", "md", "lg", "xl";

$size-map: (
  "xs": 10px,
  "sm": 14px,
  "md": 18px,
  "lg": 22px,
  "xl": 40px,
);

.infostat-fluent-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //   height: 16px;

  &.clickable:not(.disabled):hover {
    cursor: pointer;
  }

  @each $s in $sizes {
    &.size-#{$s} {
      svg,
      & > .bp4-icon {
        width: map-get($map: $size-map, $key: #{$s});
        height: map-get($map: $size-map, $key: #{$s});
      }
    }
  }

  & > .bp4-icon {
    font-style: normal;
    display: inline-block;
    path {
      fill: $grey3;
    }
  }

  &.color-subtle-dark > .bp4-icon path {
    fill: $grey4;
  }

  &.color-folder > .bp4-icon path {
    fill: $folder-orange;
  }

  &.favorite-icon > .bp4-icon path {
    fill: $folder-orange;
  }

  &.color-help-filled > .bp4-icon path {
    fill: $color-help-blue;
  }

  &.invisible {
    path {
      fill: transparent;
    }
  }
}
