@import "../lib//style/variables";

$bg-color: darken(#faf9f8, 2);

.fluent-modal-container {
  border-radius: 2px;
  border-top: 3px solid $infostat-interface-blue;
  max-width: $modal-narrow-max-width;
  min-width: css-min(350px, 90vw);
  &.grow {
    width: $modal-narrow-max-width;
  }
  &.width-md {
    width: css-min(800px, 90vw);
    max-width: 90vw;
  }
  &.width-lg {
    width: css-min(1200px, 90vw);
    max-width: 90vw;
  }
  &.cover-viewport {
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
  }

  .fluent-modal-scrollable {
    overflow: visible;
    max-height: calc(100% - 30px);
  }

  .fluent-modal-body {
    overflow-y: auto;
    padding: $size-md;
    max-height: 80vh;
    margin-bottom: $size-lg;
  }

  /**
   * On screens with lots of vertical space, we don't want the modal body to grow too large.
   * On smaller screens however, we want the body to take up as much space as needed, i.e. no scrollbars,
   * because the container will be scrolled.
   */
  @media screen and (max-height: 600px) {
    .fluent-modal-body {
      max-height: unset;
    }
  }

  .fluent-modal-footer {
    padding: $size-md $size-md;
    text-align: right;
    background-color: $bg-color;
  }

  p {
    margin-top: $size-sm;
  }

  .fluent-modal-header {
    background-color: $bg-color;
    padding: $size-md;
    h1,
    h2,
    h3 {
      margin: 0;
    }
    display: flex;
    justify-content: space-between;

    button {
      margin-left: $size-lg;
    }
  }

  // When the embedded chart is really long, the modal could end up very far down the page, or even outside
  // the visible part. This ensures it stays close to the top.
  &.geo-modal-embedded {
    margin-top: $size-md;
    align-self: flex-start;
  }
}

.fluent-modal-container-tall {
  overflow: hidden;
  @extend .fluent-modal-container;

  .fluent-modal-header {
    height: 30px;
  }
  .fluent-modal-body {
    margin-bottom: 0;
  }
  .fluent-modal-scrollable {
    height: 100%;
  }
  .fluent-modal-body {
    height: calc(100% - 60px - #{6 * $size-md} - 3px);
    max-height: unset;
  }
  .fluent-modal-scrollable {
    height: calc(100vh - 32px);
  }
  .fluent-modal-footer {
    height: 30px;
  }
}
