@import "../../../../../lib/style/variables";
@import "./info_tab_common";

.dataset-info {
  .survey-question {
    font-style: italic;
  }
  .subtle-header {
    font-weight: normal;
  }
  .list-item-name {
    font-weight: bold;
    color: $text-color-soft-black;
  }

  .grid-container {
    column-count: 3;
    column-gap: $size-sm;
    @media screen and (max-width: $breakpoint-mobile) {
      column-count: 2;
    }
  }

  .grid-item {
    break-inside: avoid;
    margin-bottom: $size-xs;
  }

  .data-source-info {
    @include measure-source-info;

    table {
      border-collapse: collapse;
      td {
        padding-left: 0;
      }
      td:not(:first-child) {
        padding-left: $size-md;
      }
    }
  }

  p {
    margin-top: 0;
  }
}
