@import "../../../../lib/style/variables";

.subject-path-selection {
  .select-or-create {
    .or-indicator {
      margin: 0 $size-md;
      font-size: 16px;
    }
    display: flex;
    align-items: flex-end;
    & > :first-child {
      flex-grow: 1;
    }
  }
}

.simple-subject-select {
  .existing-measures {
    h3,
    ul {
      margin: 0;
    }
  }
}
