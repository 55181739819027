@import "../lib/style/variables";

.vertical-divider {
  margin: 0 $size-lg;
  display: block;
  height: 65%;
  width: 1px;
  border-right: 1px solid $grey4;
}

.horizontal-divider {
  margin: $size-md 0;
  display: block;
  height: 1px;
  background-color: $light-grey4;

  &.skip-bottom-margin {
    margin-bottom: 0;
  }

  &.invisible {
    background-color: transparent;
  }
}
