@import "../lib/style/variables";

.infobox {
  h3 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: $size-md;
  }
  summary {
    font-size: 0.9rem;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
  h4 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: $size-sm;
    color: $text-color-soft-black;
  }
  p {
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .content {
    margin-top: $size-md;
  }

  padding: $size-md $size-md;
  border-radius: $border-radius-sm;
  background-color: $tool-surface-bg-color;

  margin-bottom: $size-md;
}
