@import "../../../lib/style/variables";

#data-search-preview {
  position: absolute;
  z-index: 190;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;

  .data-container {
    .card-loading-indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 200;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    max-width: $content-max-width-with-scrollbar; // Allow for scrollbar in card
    position: relative;
    height: calc(100vh - 10px);
    margin-top: auto;
    margin-bottom: auto;

    > .Card {
      position: relative;
      height: calc(100% - 40px);
      margin: 0;
      overflow: auto;
      border-radius: $border-radius-sm;
    }
    .data-card-content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
    .stats-geo-and-output-container {
      flex-grow: 1;
    }

    @media all and (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      > .Card {
        height: unset;
      }
      margin-top: 0;
      margin-bottom: 0;
      height: 100vh;
    }
  }
}
