@import "../../../../../lib/style/variables";

$MIN_EDITOR_HEIGHT: 200px;
$MAX_EDITOR_HEIGHT: 900px;
.text-card {
  max-width: $read-only-chart-card-max-width;
  .quill-container {
    @media screen {
      width: 100%;
      overflow: auto;
    }
    .ql-toolbar.ql-snow {
      border-left: none;
      border-right: none;
      border-top: none;
    }
    .ql-container {
      font-family: $font-family-card-text-default;
      h1,
      h2 {
        font-family: $font-family-card-text-headers;
      }
      border: none;
      flex-grow: 1;

      .ql-container,
      .ql-editor {
        min-height: $MIN_EDITOR_HEIGHT;
        max-height: $MAX_EDITOR_HEIGHT;
        height: unset;
      }
    }
  }

  @media print {
    .ql-editor {
      padding: 0;
      overflow: visible;
    }
    .quill .ql-toolbar {
      display: none;
    }
  }
  .quill.read-only {
    .ql-toolbar {
      display: none;
    }
  }
}
