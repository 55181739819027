@import "../../../../../../lib/style/variables";

.infostat-micro {
  .highlighted-result-popup {
    .mapboxgl-popup-content {
      padding: $size-xs $size-sm;
    }
  }
  .highlighted-result {
    border-radius: $border-radius-sm;

    p {
      margin: 0;
      &.label,
      &.value {
        text-align: right;
      }
    }
  }
}
