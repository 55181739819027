@import "../../../lib/style/variables";

.share-document-dialog {
  max-width: 800px;
  .pivot-container {
    display: flex;
    justify-content: stretch;

    min-width: css-min(600px, 80vw);
    max-width: 600px;
    min-height: css-min(500px, 80vh);
    margin-top: $size-lg;
  }

  .share-pivot {
    .sharing-line {
      display: flex;
      align-items: center;

      & > :not(:last-child) {
        margin-right: $size-sm;
      }
    }

    .member-org-recipients {
      max-height: 250px;
      border: 1px solid $light-grey1;
      padding-left: $size-sm;
      padding-bottom: $size-sm;
      overflow: auto;
      .ms-Checkbox {
        margin-top: $size-sm;
      }
    }
  }
  .share-pivot,
  .manage-access-pivot {
    flex-grow: 1;
    .sharing-line-container {
      &:not(:last-child) {
        margin-bottom: $size-md;
      }
    }
    .sharing-line {
      display: flex;
      & > :first-child {
        margin-right: $size-sm;
      }
      &:not(:last-child) {
        margin-bottom: $size-sm;
      }
    }
    .alert-with-icon {
      display: flex;
      align-items: center;
      & > :first-child {
        margin-right: $size-sm;
      }
    }
  }

  .manage-access-pivot {
    .sharing-line {
      .role-indicator {
        display: flex;
        .icon {
          margin-right: $size-xs;
        }
      }
      > :not(:last-child) {
        margin-right: $size-md;
      }
      align-items: center;
      > .grow {
        flex-grow: 1;
      }
    }
  }
}
