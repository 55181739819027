@import "../../../../../lib/style/variables";

.custom-dropdown-color-options.custom-dropdown-container {
  padding: 0 $size-md !important;
  .buttons-footer {
    margin-bottom: $size-sm;
  }
}

.color-scheme-list-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .colors {
    display: inline-flex;
    flex-grow: 1;
    margin-left: $size-sm;
    justify-content: flex-end;
  }
}

.selected-color-scheme {
  display: block;
  .colors {
    display: inline-flex;
    flex-grow: 1;
    margin-left: $size-sm;
    justify-content: flex-end;
  }
}

.color-options-dialog-stats {
  .preview {
    overflow: auto;
    flex-grow: 1;
  }
  .ms-Checkbox + .ms-Checkbox {
    margin-top: $size-xs;
  }
  .colors-list,
  .dropdowns-list {
    .dropdown-container {
      display: flex;
      align-items: center;
    }
    // grid layout which automatically shrinks to fit content
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: $size-xs;
    column-gap: $size-xs;
    margin-top: $size-md;
  }
}

.switch-color-scheme-container {
  display: flex;
  align-items: flex-end;
}

.chart-appearance-color-picker-dropdown {
  .no-color {
    margin-left: 5px; // To match MS color block components
    display: flex;
    align-items: center;
    &:hover,
    label:hover {
      cursor: pointer;
    }
    .no-color-box {
      position: relative;
      display: inline-block;
      height: 30px;
      width: 30px;
      border: 1px solid grey;
      margin-right: $size-sm;
    }
  }
}
