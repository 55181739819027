@import "../lib/style/variables";

.progress-bar {
  display: flex;
  height: 15px; // Adjust based on your needs
  flex-grow: 1;
  flex-basis: 100px;
  flex-shrink: 0;
  overflow: hidden;
}

.progress-bar-percent {
  display: flex;
  flex-direction: row;
  .progress-bar {
    flex-grow: 1;
    margin-right: $size-sm;
  }
  align-items: center;
  p {
    margin: 0;
  }
}
