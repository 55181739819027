@import "../../../lib/style/variables";

#data-admin {
  margin-top: $size-lg;
  @media screen {
    background-color: $main-bg-color;
  }
  display: flex;
  > .ms-FocusZone,
  > .data-admin-view {
    display: inline-block;
  }

  .data-admin-view {
    margin: auto;

    #create-measure {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      & > div {
        flex-grow: 1;
      }
    }
    .Card {
      min-width: 70vw;
      margin-top: 0;
      flex-grow: 1;
      min-height: $card-min-height;
      padding: $size-sm $size-md;
      padding-bottom: $size-md;
    }
  }

  .nav {
    min-width: 150px;
    max-width: 300px;
  }
}
