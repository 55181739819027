@import "../../../../../lib/style/variables";

#data-administration {
  .data-upload {
    background-color: $bg-color-soft;
    padding: $size-xs $size-sm;
    padding-bottom: $size-sm;
    border: 1px solid $grey5;
  }

  .upload-info {
    meter {
      width: 95%;
    }
  }

  tr.error {
    background-color: $intent-danger-bg-color;
  }

  .log-cell {
    &:hover {
      td {
        font-size: 140%;
      }
    }
  }

  table.log-table {
    border-collapse: collapse;

    td {
      font-size: 10px;
      padding: 0;
      padding-right: $size-xs;
      text-align: left;
      transition: font-size 0.3s ease-in-out;
    }
  }
}

.confirm-data-upload {
  .member-orgs-checks {
    display: flex;
    column-gap: $size-lg;
    max-height: 200px;
    overflow: auto;
    & > div {
      flex-grow: 1;
    }
  }
}
