@import "../../../lib/style/variables";

$icon-size: 20px;
$icon-color: $grey3;
$icon-disabled-color: $grey6;
$text-disabled-color: $grey5;
$rearrange-color: $icon-color;

.context-menu-item-with-custom-icon {
  display: flex;
  align-items: center;
  .custom-icon {
    margin-left: $size-xs;
    margin-right: $size-sm;
    display: flex;
    align-items: center;
  }
}

.infostat-toolbar-dropdown-menu {
  .ms-ContextualMenu-icon .bp4-icon svg {
    margin-top: 0.4em;
    path {
      fill: $icon-color;
    }
  }

  .ms-ContextualMenu-icon.alerts-inactive .bp4-icon svg path {
    fill: none;
    stroke: $icon-color;
  }
}

#stats-toolbar {
  box-shadow: 0px 1px 2px $grey6;
  background-color: $tool-surface-bg-color;
  z-index: 50;
  overflow-x: auto;

  display: flex;
  justify-content: center;

  .toolbar-inner {
    margin: $size-sm 0;
    max-width: calc(#{$content-max-width} - #{$size-sm} * 2);
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .toolbar-group {
      display: flex;
    }
  }

  .toolbar-item {
    &:not(:last-child) {
      border-right: 1px solid #aaa;
    }

    .big-button {
      margin: 0 $size-md;
      padding: $size-xs $size-sm;
      min-width: 50px;
      border-radius: 2px;
      display: flex;
      justify-content: center;

      .button-main,
      .button-side-indicator {
        text-align: center;
      }
      .button-side-indicator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: $size-sm;
        path {
          fill: $icon-color;
        }
      }

      .icon-container {
        text-align: center;
        svg {
          width: $icon-size;
          height: $icon-size;
        }
      }

      &:not(.disabled) {
        .icon-container {
          &:not(.icon-styled) {
            path {
              fill: $icon-color;
            }
          }
        }
      }

      &:not(.disabled) .button-main .icon-styled {
        &.new-card path {
          fill: $infostat-interface-blue;
        }
        &.save path {
          fill: $dark-blue;
        }
        &.folder path {
          fill: $folder-orange;
        }
        &.share path {
          fill: lighten($dark-blue, 10);
        }
        &.rearrange path {
          fill: $rearrange-color;
        }
      }

      &:not(.disabled):hover {
        cursor: pointer;
        background-color: $tool-surface-bg-color-hover;
      }
      &.disabled {
        .icon-container .icon-styled path,
        path {
          fill: $icon-disabled-color;
        }
        color: $text-disabled-color;
      }
    }
  }
}
