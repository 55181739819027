@import "../../../../../lib/style/variables";
@import "../card_general/card_container_shared";

.dropdown-option-infostat-surveys {
  .infostat-logo {
    height: 17px;
    vertical-align: text-bottom;
    margin-right: $size-sm;
  }
}

.data-card-content {
  @include common-card-content-styles;
  @include data-selections;

  .infostat-search-box {
    margin: $size-lg 0;
  }

  .grouping-header-line {
    padding: 0 map-get($std-sizes, "lg");
    display: flex;
    align-items: center;
    .header {
      margin: 0;
    }
    margin-bottom: $size-sm;
    .grouping-search {
      margin-left: $size-lg;
    }
  }

  .clear-row {
    display: block;
  }

  .settings-container {
    padding: 0 map-get($std-sizes, "lg");
    display: flex;
  }
}
