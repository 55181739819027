@import "../../../../../lib/style/variables";

$MIN_EDITOR_HEIGHT: 200px;
$MAX_EDITOR_HEIGHT: 900px;

.text-card-ck {
  max-width: $read-only-chart-card-max-width;

  &.read-only {
    .ck.ck-widget__selection-handle {
      display: none !important;
    }

    .raw-html-embed__buttons-wrapper,
    .raw-html-embed__preview-placeholder {
      display: none !important;
    }
    .raw-html-embed,
    .raw-html-embed__preview-content {
      outline: none !important;
    }
    .raw-html-embed:before {
      content: none !important;
    }
    figure.table {
      pointer-events: none;
    }
    figure.table * {
      pointer-events: all;
    }
    .ck-content .table table td,
    .ck-content .table table th,
    .ck-content .table table {
      border: none;
    }
  }

  .raw-html-embed,
  .raw-html-embed__preview-content {
    background: none !important;
  }

  .ck.ck-editor__editable_inline > :first-child {
    margin-top: var(--ck-spacing-small);
  }

  .ck-heading_custom_title {
    margin-top: 0;
    font-size: 40px;
  }

  .ck.ck-toolbar {
    border-width: 0px;
  }
  &:not(.custom-margins) {
    .ck.ck-content {
      padding: $size-sm $size-xl $size-lg $size-xl;
    }

    @media screen and (max-width: $screen-sm) {
      padding: $size-sm $size-md $size-sm $size-md;
    }
  }
  &.custom-margins {
    .ck.ck-editor__editable_inline {
      padding: 0;
      & > :first-child {
        margin-top: 0;
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }

  .ck.ck-content {
    border-width: 1px 0 0 0;
    min-height: $MIN_EDITOR_HEIGHT;
    figcaption {
      text-align: left;
    }
  }
  .ck.ck-content.ck-read-only {
    border-width: 0;
    --ck-widget-outline-thickness: 0;
  }
}
