@import "../../../../lib/style/variables";

#secure-delivery {
  table {
    th:nth-child(3),
    th:nth-child(4),
    td:nth-child(3),
    td:nth-child(4) {
      text-align: right;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }
  }

  .delivery-link {
    text-decoration: underline;
    &:hover {
      //   color: $link-color;
      cursor: pointer;
    }
  }
}

.secure-delivery-modal {
  .options {
    display: flex;
    align-items: flex-end;
    gap: $size-lg;
    margin-bottom: $size-md;
  }
}
