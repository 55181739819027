@import "../../../../lib/style/variables";

#file-area-manager {
  .actions-panel {
    margin-bottom: $size-sm;

    .upload-button {
      display: inline-block;
      fill: white;
      font-weight: 600;
      font-family: inherit;
      font-size: 90%;
      border: 1px solid $grey4;
      padding: 0 16px;
      border-radius: 2px;
      &:not(:first-child) {
        margin-left: map-get($std-sizes, "sm");
      }

      label {
        line-height: 22px;
      }

      .is-disabled {
        background-color: $button-bg-disabled;
        color: $text-color-disabled;
      }
    }
  }

  .node-list {
    list-style-type: none;
    padding: 0;

    table {
      width: 100%;
      border-collapse: collapse;
      tbody > tr {
        cursor: pointer;
        user-select: none;
      }
      tr > td:first-child {
        width: 20px;
      }
      thead th {
        text-align: left;
      }

      .file-status-cell {
        display: flex;
        align-items: center;
      }

      .status-text {
        padding: 2px 4px;
      }
      tr.uploaded .file-status-cell .status-text {
        background-color: $intent-warning-bg-color;
      }
      tr.ongoing .file-status-cell .status-text {
        background-color: $intent-primary-bg-color;
      }
      tr.done .file-status-cell .status-text {
        background-color: $intent-success-bg-color;
      }
    }

    li {
      cursor: pointer;
    }
    .selected {
      background-color: #f0f0f0;
    }
  }
}
