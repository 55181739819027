@import "../../lib/style/variables";

.page-break-before {
  page-break-before: always;
}
.page-break-after {
  page-break-after: always;
}

.document-card-container {
  &:not(.editing-document) {
    .page-break-indicator {
      span {
        display: none;
      }
    }
  }
  &.editing-document {
    .page-break-indicator {
      span {
        display: block;
      }
    }
  }
}

.page-break-indicator {
  display: flex;
  margin: auto;
  font-size: 12px;
  color: $text-color-subdued;
  span {
    margin: auto;
  }
  @media print {
    span {
      display: none;
    }
  }
}
