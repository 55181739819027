@import "../../lib/style/variables";

.svg-chart-container {
  position: relative;
  .legend-label-container:hover {
    cursor: default;
  }
}

#overlay-container {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  padding: map-get($map: $std-sizes, $key: sm)
    map-get($map: $std-sizes, $key: sm);
  border-radius: 2px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

  max-height: 800px;
  overflow-y: hidden;

  table {
    tr:not(:last-child) td {
      padding-bottom: map-get($map: $std-sizes, $key: xs);
    }

    border-collapse: collapse;
    font-size: 12px;
    .value {
      white-space: nowrap;
      padding-left: map-get($map: $std-sizes, $key: md);
      text-align: right;
    }
    .color-indicator,
    .label,
    .value {
      vertical-align: middle;
    }
    .color-indicator {
      font-size: 10px;
      padding-right: map-get($map: $std-sizes, $key: sm);
    }
  }

  .hover-header {
    flex-grow: 0;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: $size-xs;
    &:last-child {
      margin-bottom: map-get($map: $std-sizes, $key: sm);
    }
  }
}
