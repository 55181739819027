@import "../../../lib/style/variables";

.reports-view {
  overflow-y: auto;

  .latest-updates {
    display: flex;

    .Card:first-child {
      margin-bottom: 0;
    }
    .Card:nth-child(2) {
      margin-left: $size-sm;
      margin-bottom: 0;
    }
    .Card {
      margin-top: 0;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      flex-wrap: wrap;

      .Card:first-child {
        margin-top: 0;
      }

      .Card:first-child {
        margin-left: $size-lg;
        margin-right: $size-lg;
        margin-bottom: $size-lg;
      }
      .Card:last-child {
        margin-left: $size-lg;
        margin-right: $size-lg;
        margin-bottom: $size-xs;
      }
    }

    > div {
      flex-grow: 1;
      min-width: 40%;
    }
  }

  .ms-MessageBar {
    width: unset;
  }

  .docs-pivot-container {
    overflow-x: auto;
    & > .ms-Pivot {
      display: flex;
    }
  }

  .home-search-container {
    margin: map-get($std-sizes, "lg");
    display: flex;
    justify-content: center;
  }

  .search-stats {
    padding: $size-sm $size-lg;
  }

  .reports-view-content {
    padding: $size-sm $size-lg;
    padding-bottom: $size-lg;
  }

  .infostat-filelist {
    .ms-Check-circle {
      top: 1px;
      path {
        fill: white;
      }
    }
    .ms-Check-check {
      svg {
        width: 12px;
        height: 12px;
        margin-right: 1px;
      }
      fill: transparent;
    }
  }
}
