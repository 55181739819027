@import "./lib/style/variables";

// All fonts/font files are specified in public/fonts.css.
// Circular fonts in particular need to be loaded by the browser on load
// to predictably load those fonts when rendering SVGs to PNG (Safari).

#size-measurements {
  font-family: $svg-fonts;
  opacity: 0;
  position: fixed;
  top: 0;
}

body {
  margin: 0;
  font-family: $font-family-ui-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html:not(.embedded-frame) {
  body {
    height: 100vh;
  }
}

html.embedded-frame {
  height: 100%;
  body,
  .theme-provider-root,
  #root {
    height: 100%;
  }
}

// Use Selawik semi light for big headers
h1,
h2 {
  margin-top: 0;
  font-weight: 300;
}
h3,
h4 {
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 0.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// TODO: handle different sizes if we continue using these icons
.infostat-icon {
  width: 18px;
  height: 18px;
}

/** Modifications of Fluent UI styles **/

i.ms-Checkbox-checkmark svg {
  fill: white;
  margin-top: 2px;
}

.ms-Dropdown-container i.ms-Dropdown-caretDown span.bp4-icon svg {
  vertical-align: text-bottom;
  fill: $grey5;
}

.ms-ContextualMenu-link .ms-ContextualMenu-submenuIcon .bp4-icon svg {
  vertical-align: text-bottom;
}

.ms-ContextualMenu-list .is-disabled svg path {
  fill: $grey6;
}

/** SVG _disable_ slashed zero. Note that this won't work for standalone
    SVG exports unless styles are embedded. **/
text {
  font-feature-settings: "zero" on;
}

text.mixed-alpha-numeric {
  // font-feature-settings: "zero" on, "tnum";
}

// Debug: use to visalize groups in SVG
// g:hover {
//   outline: 1px solid red;
// }
