@import "../lib/style/variables";

$hard-border-style: 1px solid black;
$hard-border-style-outermost: 2px solid black;

.infostat-data-table {
  @media print {
    .sort-indicator {
      display: none;
    }
  }

  table {
    border-top: $hard-border-style-outermost;
    border-collapse: collapse;

    tr.last {
      border-bottom: $hard-border-style-outermost;
    }
    // Add a top border if we have a summary section that does not immediately follow the original last table row.
    // Applicable if we have two summary sections, e.g. one with sum, avg, etc and one with regional aggregations.
    :not(tr.last) + tr.first-summary-row {
      border-top: $hard-border-style-outermost;
    }
    tr.sum-row {
      border-bottom: $hard-border-style;
    }

    thead tr:last-child {
      border-bottom: $hard-border-style;
    }

    td,
    th {
      min-width: 6rem;
      padding: 0.2rem 0.5rem;
    }

    th {
      text-align: center;
      font-weight: normal;
    }
    th.primary-dimension-header {
      text-align: left;
    }

    td.forecast,
    tr.forecast td,
    th.forecast .header-text {
      font-style: italic;
    }

    tr:not(.super-columns) th {
      &.sortable:hover {
        cursor: pointer;
      }

      .header-container {
        display: flex;
        align-items: center;

        .header-text {
          flex-grow: 1;
          text-align: center;
        }
        .sort-indicator {
          flex-grow: 0;
          svg {
            vertical-align: text-bottom;
          }
        }
      }
    }

    td {
      &.decimal,
      &.integer,
      &.survey {
        text-align: right;
      }
      &.no-value {
        text-align: center;
      }
      .significant-change-positive {
        background-color: #95d36f;
        padding: 0 2px;
      }
      .significant-change-negative {
        background-color: #f8a1a1;
        padding: 0 2px;
      }
    }
  }

  .significant-changes-legend {
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
    }

    .survey-legend-change-positive {
      margin-right: $size-xs;
      display: block;
      width: 12px;
      height: 12px;
      background-color: #95d36f;
    }

    .survey-legend-change-negative {
      margin-right: $size-xs;
      display: block;
      width: 12px;
      height: 12px;
      background-color: #f8a1a1;
    }
  }
}
