#data-admin .data-admin-view #manage-migrations .Card {
  min-width: 0;
}

#manage-migrations {
  width: 1400px;

  .doc-list {
    max-height: 1000px;
    overflow: auto;
  }

  #doc-migration-comparison {
    display: flex;

    .doc {
      flex-grow: 1;
      max-width: 95%;
    }
  }
}
