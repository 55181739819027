@import "@fluentui/react/dist/sass/References";

@import "../lib/style/variables.scss";

$card-border-radius: 0;
$card-tab-border-radius: $border-radius-sm;

.Card {
  @media screen {
    @include ms-depth-4;
    border-radius: $card-border-radius;
    background-color: white;
  }
  margin: map-get($std-sizes, "lg");
  &.std-padding {
    padding: map-get($std-sizes, "lg");
  }

  h2 {
    font-weight: normal;
  }

  > h1,
  > h2,
  > h3,
  > h4 {
    &:first-child {
      margin-top: 0;
    }
  }
}

$border-style: 1px solid $grey6;

.CardWithTab {
  border-radius: $card-border-radius;
  &:first-child {
    margin-top: $size-md;
  }
  margin-bottom: $size-sm;

  &.std-padding {
    padding: map-get($std-sizes, "lg");
  }

  .card-main {
    background-color: white;
    padding-bottom: $card-border-radius;
    border-radius: 0 0 $card-border-radius $card-border-radius;
    border-left: $border-style;
    border-right: $border-style;
    border-bottom: $border-style;
    @media print {
      border: none;
      border-radius: 0;
      background-color: transparent;
    }
  }

  &.read-only.text-card-ck.custom-bg .card-main {
    background-color: inherit;
    .ck-editor {
      background-color: inherit;
      .ck-editor__main {
        background-color: inherit;
        .ck-content {
          background-color: inherit;
        }
      }
    }
  }

  @media screen {
    &:not(.editing-document).read-only {
      .header-space {
        display: none;
      }
      .card-main {
        border-top: $border-style;
      }

      &.hide-space-before:not(:first-child) {
        .breakdowns-container,
        .micro-measure-selection-container {
          padding-top: 70px;
        }
        margin-top: 0;
        .card-main {
          border-top: none;
        }
      }
      &.hide-space-after:not(:last-child) {
        margin-bottom: 0;
        .card-main {
          border-bottom: none;
        }
      }
    }
  }

  .header-space {
    display: flex;
    align-items: flex-end;

    @media print {
      display: none;
    }

    .tab {
      flex-grow: 1;
      background-color: white;
      border-radius: $card-tab-border-radius $card-tab-border-radius 0 0;
      border-top: $border-style;
      border-left: $border-style;
      border-right: $border-style;
      padding: $size-xs $size-md;
    }
    .tab-column {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      & + .tab-column .tab {
        border-left: none;
      }

      &:not(.selected) {
        &:hover {
          cursor: pointer;
        }
        .tab {
          background-color: $bg-color-soft;
          border-bottom: $border-style;
        }
      }
    }
    .left-of-tab {
      border-top: $border-style;
      border-left: $border-style;
      width: 30px;
    }
    .left-of-tab,
    .right-of-tab,
    .empty-block {
      background-color: white;
    }
    .right-of-tab {
      border-top: $border-style;
      border-right: $border-style;
      flex-grow: 1;
    }
  }

  &.custom-bg .header-space .tab {
    background-color: inherit;
  }

  &.hide-tab {
    .tab-column {
      display: none;
    }
  }

  > h1,
  > h2,
  > h3,
  > h4 {
    &:first-child {
      margin-top: 0;
    }
  }
}
