@import "../../../../lib/style/variables";

$link-color: $text-color-soft-black;

.infostat-filetree {
  .menu-container {
    margin-top: $size-md;
  }
}

.infostat-filelist-flat .draft-warning {
  margin-top: $size-md;
  margin-bottom: 0;
}

.infostat-filelist,
.infostat-filetree,
.infostat-filelist-flat,
.file-move-dialog {
  .ms-Breadcrumb-chevron {
    // Adjust Blueprint icon to fit with FluentUI positioning
    margin-top: 0.3em;
  }

  .infostat-fluent-icon {
    // Adjust Blueprint icon to fit with FluentUI positioning
    margin-top: 0.1em;
  }

  .node-icons {
    display: flex;
    > :not(:last-child) {
      margin-right: $size-sm;
    }
  }

  .ms-DetailsRow-cell {
    .favorite-icon {
      opacity: 0.5;
    }
  }
  .ms-DetailsRow:hover .ms-DetailsRow-cell {
    & > .ms-Link {
      color: $link-color;
    }
    .favorite-icon {
      opacity: 1;
    }
  }
  .name-column button {
    color: $link-color;
  }

  .node-title-col {
    .grow {
      flex-grow: 1;
    }
    display: flex;
    white-space: break-spaces;
    .infostat-fluent-icon {
      margin-left: $size-md;
    }
  }

  .highlight-folder {
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-name: highlight-folder;
  }
}

@keyframes highlight-folder {
  50% {
    transform: scale(0.95);
  }
}
