@import "../../../../../lib/style/variables";

.stats-map-color-scale-option {
  canvas {
    vertical-align: middle;
  }
  flex-grow: 1;
}

.stats-map-colors-and-scales-dialog {
  .breakpoints-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: minmax(0, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-auto-flow: column;
    column-gap: $size-md;

    .ms-TextField {
      margin-bottom: 0; // Remove default margin to use grid gap
    }
  }

  label.ms-Dropdown-label {
    padding: 0;
  }

  .custom-colors {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(0, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-auto-flow: column;
    gap: $size-sm;
    .color-dropdown-container {
      display: flex;
      & > {
        flex-grow: 0;
      }
      label {
        white-space: nowrap;
      }
    }
  }

  .ms-TextField {
    margin-bottom: $size-sm;
  }

  .ms-Checkbox {
    margin-top: $size-md;
  }
}
