@import "../../../../../lib/style/variables";

.fine-tune-breakdowns-dialog {
  .hidden-breakdowns {
    display: flex;
    flex-wrap: wrap;
  }
  .computed-values {
    display: flex;

    table {
      flex-grow: 1;
      border-collapse: collapse;
      td:not(:last-child) {
        padding-right: $size-md;
      }
    }
  }
  .tag {
    &:not(:last-child) {
      margin-right: $size-sm;
    }
    margin-top: $size-sm;
    font-weight: bold;
    border-radius: $border-radius-sm;
    background-color: lighten($infostat-interface-blue, 30);
    font-size: 14px;
    display: flex;
    align-items: center;
    .name {
      padding: 0 $size-sm;
    }
    .button {
      border-radius: $border-radius-sm;
      padding: 0 $size-xs;
      border: 1px solid $grey2;
      &:hover {
        cursor: pointer;
        background-color: lighten(red, 25);
      }
    }
  }
}
