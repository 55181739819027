@import "../lib/style/variables";

.alert-box {
  padding: $size-sm $size-sm;
  border-radius: $border-radius-sm;

  &.default {
    background-color: $intent-default-bg-color;
  }
  &.warning {
    background-color: $intent-warning-bg-color;
  }
  &.danger {
    background-color: $intent-danger-bg-color;
  }
  &.primary {
    background-color: $intent-primary-bg-color;
  }
  &.success {
    background-color: $intent-success-bg-color;
  }

  &:not(:last-child) {
    margin-bottom: $size-md;
  }
}
