@import "../../../lib/style/variables";

#user-settings {
  .dummy {
    flex-grow: 1;
  }
  overflow: auto;
  h2 {
    font-weight: bold;
  }

  .content {
    margin-top: $size-md;
  }
  .back-button {
    margin-top: $size-sm;
    margin-left: $size-sm;
  }

  #main-content {
    display: flex;
  }

  .settings-view {
    flex-grow: 1;
    max-width: 100vw;
  }

  .settings-view .Card {
    flex-grow: 1;
    margin-top: 0;
    min-height: $card-min-height;
    padding: $size-sm $size-md;

    @media screen and (max-width: $breakpoint-mobile) {
      margin: 0 $size-sm;
    }
  }
  .nav {
    min-width: 150px;
    max-width: 300px;
  }

  #alerts-settings {
    .measure-description,
    .document-name {
      color: $text-color-soft-black;
    }
    .button-column {
      display: inline-flex;
      justify-content: center;
    }
  }

  .custom-navbar-small-screen {
    background-color: $bg-color-soft;
    // slight box shadow
    box-shadow: 0 1px 2px $grey6;
    padding: $size-md $size-md;
    display: flex;
    .hamburger {
      margin-right: $size-md;
      padding-top: $size-xs;
      &:hover {
        cursor: pointer;
      }
    }
    .label {
      font-size: $font-size-menu-header;
      font-weight: bold;
    }
  }

  .custom-nav-small-screen-overlay {
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

    transition: background-color 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0);
    transform: translateX(-100%);

    .custom-nav-small-screen-container {
      transition: transform 0.2s ease-in-out;
      transform: translateX(-100%);
      display: flex;
      .close-button {
        margin: $size-sm;
      }
    }
    .custom-nav-small-screen {
      flex-grow: 1;
      max-width: css-min(400px, calc(100vw - 100px));
      height: 100vh;
      background-color: $bg-color-soft;
      padding: 0 $size-md $size-md 0;
    }

    &.is-open {
      transform: translateX(0);
      background-color: rgba(0, 0, 0, 0.5);

      .custom-nav-small-screen-container {
        transform: translateX(0);
      }
    }
  }

  .custom-nav-inner.big-screen-only {
    min-width: 200px;
  }

  .custom-nav-inner {
    display: flex;
    flex-direction: column;

    .group-header {
      font-weight: bold;
      font-size: $font-size-menu-header;
      margin-top: $size-md;
      margin-bottom: 0;
      // padding-left: $size-md;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: $size-sm 0;

      li {
        font-size: $font-size-menu-item;
        cursor: pointer;
        padding: $size-sm;
        padding-left: $size-md;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: lighten($infostat-interface-blue, 35%);
        }

        &.active {
          border-left: 3px solid $infostat-interface-blue;
          background-color: lighten($infostat-interface-blue, 30%);
        }
      }
    }

    ul li,
    .group-header {
      border-left: 3px solid transparent;
    }
  }

  #org-license-management {
    .Card {
      min-height: 0;
    }
    .dropdown {
      flex-grow: 1;
      max-width: 300px;
    }
    .infostat-table-container .action-column {
      min-width: 100px;
    }
  }

  #appearance-settings,
  #org-appearance-settings {
    .edit-theme,
    .theme-view {
      details {
        margin-bottom: $size-sm;
        summary {
          font-weight: bold;
          cursor: pointer;
        }
        .details-body,
        .details-body-block {
          .ms-Dropdown-container:not(:last-child) {
            margin-right: $size-md;
          }
          flex-wrap: wrap;
          align-items: flex-end;
          display: flex;
        }
      }

      .special-colors-option {
        max-width: 300px;
      }

      .ms-Dropdown-container {
        max-width: $text-input-narrow-width;
      }

      padding: $size-sm $size-md;
      border: 1px solid $grey6;
      border-radius: $border-radius-sm;
      margin-bottom: $size-md;
      .special-colors,
      .advanced-gridlines-settings {
        display: flex;
        > div {
          margin-right: $size-sm;
        }
        label {
          font-weight: bold;
        }
      }

      .theme-and-preview {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .left {
          display: flex;
        }
        .buttons-container {
          margin-bottom: $size-md;
        }
      }
    }

    .preview-mode {
      margin-bottom: $size-lg;
      .ms-ChoiceField {
        margin-right: $size-md;
      }
    }

    .theme-view {
      &.default {
        border-color: $grey2;
        box-shadow: inset 0 0 7px $infostat-interface-blue;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .default-button {
        margin-right: $size-md;
      }
    }

    .color-grid {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-gap: $size-sm;
      justify-content: flex-start;
      margin-bottom: $size-md;
    }
  }
}
