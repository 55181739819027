.print-dummy-container {
  @media screen {
    display: none;
  }
  @media print {
    display: block;
    width: 10px;
    height: 2px;
  }
}
