.heading-with-icon {
  display: flex;
  align-items: center;

  h1,
  h3,
  h4 {
    margin-bottom: 0;
    margin-left: 0.4em;
  }

  margin-bottom: 0.5em;
}
