@import "../lib/style/variables";

#main-content-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  #main-content {
    flex-grow: 1;
    max-width: $content-max-width;
  }
}

.page-content-container {
  display: flex;
  justify-content: center;
  .page-content {
    flex-grow: 1;
    max-width: $content-max-width;
  }
}
