@import "../../../../../../lib/style/variables";

.micro-selection-panel {
  border-radius: $border-radius-sm;
  background-color: rgba(255, 255, 255, 0.8);
  padding: $size-xs $size-sm;

  section {
    margin-bottom: $size-xs;
    &:last-of-type {
      margin-bottom: $size-sm;
    }
  }

  .panel-container {
    padding: $size-xs $size-sm;
  }

  h3 {
    font-size: 16px;
    padding-top: 1px;
  }

  strong {
    font-size: 110%;
  }

  .user-defined-region {
    margin-left: $size-md;
    display: flex;
    justify-content: space-between;
    &.disabled {
      * {
        color: $grey5;
      }
      .left path {
        fill: $grey5;
      }
    }
  }

  .deselectable-region,
  .user-defined-region {
    .left i {
      vertical-align: text-top;
    }
    .infostat-fluent-icon {
      padding-right: $size-xs;
    }
  }
}
