@import "../../../../../../lib/style/variables";

$legend-box-side: 15px;

$bg-color: rgba(white, 1);
$bg-color-high-transparency: rgba(white, 0.6);
$legend-height: 120px;

$legend-symbol-col-width: 20px;
$legend-symbol-line-width: 20px;
$legend-symbol-point-width: 14px;
$legend-symbol-polygon-width: 14px;

.micro-results-panel {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  max-height: 570px;

  .legend-container {
    text-align: right;
    display: flex;
    justify-content: right;
  }

  .results-panel-part:not(:first-child) {
    margin-top: $size-md;
  }

  .legend,
  .geo-legend,
  .manual-legend {
    border-radius: $border-radius-sm;
    background-color: $bg-color;
    padding: $size-sm;
  }

  .legend {
    .canvas-legend-container {
      display: flex;
      justify-content: right;
      .labels {
        z-index: 99;
        span {
          color: black;
        }
        margin-top: $size-sm;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        height: $legend-height;
        margin-right: $size-sm;
      }
      .color-ramp {
        height: $legend-height;
      }
    }
    .legend-item {
      flex-grow: 0;
      align-items: center;
      display: flex;
    }
  }

  .geo-legend {
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: auto;
  }

  .manual-legend,
  .geo-legend {
    overflow: auto;
  }

  .manual-legend {
    padding-right: $size-lg;
  }

  .geo-legend-item {
    display: flex;
    align-items: center;
  }

  .geo-legend-symbol {
    width: $legend-symbol-col-width;
    height: 20px; /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .geo-legend-polygon {
    width: 100%; /* Adjust width and height to fit inside the symbol */
    height: 100%;
  }

  .geo-legend-label {
    font-size: 14px;
  }

  .geo-legend-symbol.point {
    border-radius: 50%;
    margin-left: ($legend-symbol-col-width - $legend-symbol-point-width) / 2;
    margin-right: 8px + ($legend-symbol-col-width - $legend-symbol-point-width) /
      2;
    width: $legend-symbol-point-width;
    height: $legend-symbol-point-width;
  }

  .geo-legend-symbol.line {
    width: $legend-symbol-line-width;
    height: 3px;
    background-color: transparent;
  }

  .geo-legend-line {
    width: 100%;
    height: 3px;
  }

  .table-results {
    background-color: $bg-color;
    padding: $size-sm;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    max-height: 200px;
    overflow: auto;
    line-height: 100%;
    td:first-child {
      padding-right: $size-sm;
    }
    td.value {
      text-align: right;
    }
    .table-container {
      display: flex;
      table {
        border-collapse: collapse;
        flex-grow: 1;
      }
    }
  }
  .filled-bar {
    background-color: rgba($grey7, 0.5);
    .fill {
      background-color: $infostat-interface-blue;
    }
  }

  h3 {
    margin-bottom: $size-sm;
  }
  p {
    margin-bottom: $size-xs;
    margin-top: 0;
    &:last-child {
      margin-top: $size-sm;
    }
  }
}
