@import "../lib/style/variables";

.infostat-button {
  &:not(:first-child) {
    margin-left: map-get($std-sizes, "sm");
  }
  &.large {
    font-size: 120%;
    height: $btn-lg-height;
  }
  &.small {
    font-size: 90%;
    height: $btn-sm-height;
    min-height: $btn-sm-height;
  }

  &.danger {
    background-color: $primary-red;
    color: white;
  }

  &.primary,
  &.danger {
    svg {
      fill: white;
    }
  }

  &.is-disabled.danger {
    background-color: $button-bg-disabled;
    color: $text-color-disabled;
  }

  &:disabled {
    svg path {
      fill: $text-color-disabled;
    }
  }

  &.color-folder .bp4-icon path {
    fill: $folder-orange;
  }
}

.button-with-side-spinner {
  display: flex;

  .spinner {
    margin-left: $size-md;
  }
}
