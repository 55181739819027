@import "../../../../../../lib/style/variables";

.micro-geo-quick-select {
  .geo-level:not(:first-child) {
    .region {
      .icon-add {
        margin-top: $size-xs;
      }
    }
  }

  .geo-level {
    .region {
      display: flex;
      justify-content: space-between;
      .text-wrapper {
        display: flex;
        align-items: center;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .municipality {
      display: flex;
      align-items: center;
      .icon-add {
        margin-right: $size-xs;
      }
      &:not(:first-child) {
        .icon-add {
          //   margin-top: $size-xs;
        }
      }
    }
  }
}
