@import "../lib/style/variables";

.infostat-table-container {
  display: flex;

  table.infostat-table {
    &:not(.no-grow) {
      flex-grow: 1;
    }
    border-collapse: collapse;

    thead {
      th.text {
        text-align: left;
      }
    }

    tr {
      border-bottom: 1px solid $table-separator-color;
    }

    th,
    td {
      padding: map-get($std-sizes, "md") map-get($std-sizes, "md");
    }
    td,
    th {
      font-size: $table-font-size;
    }

    &.slim {
      th,
      td {
        padding: $size-xs $size-xs;
      }
    }

    td.align-right {
      text-align: right;
    }
    td.align-center {
      text-align: center;
    }
    td.clickable:hover {
      background-color: $table-separator-color;
      cursor: pointer;
    }

    // Table with clickable rows
    &.clickable-rows tbody tr:hover {
      background-color: $table-separator-color;
      cursor: pointer;
    }
  }
}
