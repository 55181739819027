@import "../../../../../lib/style/variables";

$hard-border-style: 1px solid black;
$hard-border-style-outermost: 2px solid black;

.infostat-survey-string-table {
  @media print {
    .sort-indicator {
      display: none;
    }
  }

  table {
    border-top: $hard-border-style-outermost;
    border-collapse: collapse;

    tr.last {
      border-bottom: $hard-border-style-outermost;
    }

    thead tr:last-child {
      border-bottom: $hard-border-style;
    }

    tbody tr:nth-child(even) td {
      background-color: $soft-grey;
    }

    td,
    th {
      min-width: 6rem;
      padding: 0.2rem 0.5rem;
    }

    th {
      text-align: center;
      font-weight: normal;
    }
    th.primary-dimension-header {
      text-align: left;
    }

    tr th {
      &.sortable:hover {
        cursor: pointer;
      }

      .header-container {
        display: flex;

        .header-text {
          flex-grow: 1;
          text-align: center;
        }
        .sort-indicator {
          flex-grow: 0;
          svg {
            vertical-align: text-bottom;
          }
        }
      }
    }

    .th-container {
      &:hover {
        cursor: pointer;
      }

      display: flex;
      align-items: center;

      .header-text {
        flex-grow: 1;
        text-align: center;
      }

      .sort-indicator {
        flex-grow: 0;
        svg {
          vertical-align: text-bottom;
        }
      }
    }

    td {
      &.cut-indicator {
        text-align: center;
      }
      &.decimal {
        text-align: right;
      }
      p.survey-string-value-line {
        margin: 0 0 10px 0;
        padding: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
