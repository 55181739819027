@import "../../../../../../lib/style/variables";

.micro-measure-selection-entry-with-icon {
  display: flex;
  align-items: center;
  .icon {
    margin-right: $size-xs;
    display: flex;
    align-items: center;
  }
  &.fixed-width > .text {
    max-width: 186px;
    overflow: hidden;
  }
}

.third-party-doc .micro-measure-selection {
  display: flex;
  justify-content: center;
}

.micro-measure-selection-container {
  .micro-measure-selection {
    padding: $size-sm $size-lg;
    .item {
      display: inline-block;
      width: $text-input-default-width;
      margin-right: $size-md;
    }

    .remove-button-container {
      display: inline-flex;
      align-items: center;
      .mock-container {
        display: inline-block;
        overflow: hidden;
        width: 0px;
      }
    }
  }

  .third-party-doc-no-controls-above-toolbar {
    padding: 0;
  }

  .data-options {
    display: flex;
    align-items: flex-end;
    padding: $size-sm $size-lg;

    .item:not(:last-child) {
      margin-right: $size-lg;
    }

    .multi-choice-toggle {
      .info-button {
        margin-left: $size-sm;
        margin-right: $size-lg;
      }
    }
  }

  .warnings {
    margin-top: $size-md;
  }
  .dimension-selection-and-filter {
    display: inline-flex;
    align-items: flex-start;

    .dimension-selection-item {
      display: inline-flex;
    }

    .add-filter-button {
      display: flex;
      padding-bottom: $size-xs;
      align-self: flex-end;
      align-items: center;
      .invisible {
        width: 0;
        overflow: hidden;
      }
    }
  }

  .variable-edit-container {
    margin-left: $size-sm;
    margin-right: $size-sm;
    display: flex;
    align-items: center;
    align-self: flex-end;
    .infostat-fluent-icon:not(:last-child) {
      margin-right: $size-sm;
    }
    .invisible {
      overflow: hidden;
      width: 0px;
    }
  }

  .variable-input-numeric {
    input {
      max-width: 70px;
      text-align: right;
    }
    .ms-Label {
      font-size: 12px; // To match size of labels for other fields
    }
  }

  .third-party-doc-filter-measure,
  .third-party-doc-filter-breakdown {
    .ms-Label {
      font-size: $condensed-field-label-size;
    }
  }

  .filter-selector {
    display: flex;

    .filter-container {
      &.invisible {
        overflow: hidden;
        width: 0px;
      }
      display: flex;
      align-items: flex-end;
    }

    .number-input {
      input {
        max-width: 70px;
        text-align: right;
      }
    }

    .filter-container .item {
      width: auto;
      min-width: calc($text-input-default-width / 2);
    }

    .edit-container {
      display: flex;
      align-items: center;
      align-self: flex-end;
      .infostat-fluent-icon:not(:last-child) {
        margin-right: $size-sm;
      }
      .invisible {
        overflow: hidden;
        width: 0px;
      }
    }

    .active-filter {
      display: flex;
      align-self: flex-end;

      > :first-child path {
        fill: $grey6;
      }
      > * {
        margin-right: $size-sm;
      }

      .displayed-filter {
        & > :first-child {
          margin-right: $size-xs;
        }

        .ms-Label {
          font-size: $condensed-field-label-size;
        }
      }
    }
  }
}
