@import "../../../../../lib/style/variables";

$all-values-eq-color: #7ae1d8;
$invalid-color: $intent-danger-bg-color;
$disabled-color: $grey8;

#create-survey-measure {
  .table-wrapper {
    overflow: auto;
    max-width: 85vw;
  }

  .color-explanation {
    .color-square {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      border: 1px solid #ccc;
      display: inline-block;
      vertical-align: text-bottom;

      &.all-values-eq {
        background-color: $all-values-eq-color;
      }
      &.invalid {
        background-color: $invalid-color;
      }
      &.disabled {
        background-color: $disabled-color;
      }
      &.new-category {
        background-color: $warning-yellow-bg;
      }
    }
  }

  .table-output {
    max-width: 80vw;
    overflow: auto;
  }

  table {
    border-collapse: collapse;

    td,
    th {
      font-family: monospace;
      padding: 0 $size-sm;
      border: 1px solid $grey7;

      &.hidden {
        display: none;
      }
    }
    th {
      font-size: 11px;
      white-space: nowrap;
      &.all-values-eq {
        background-color: $all-values-eq-color;
      }
      border-bottom: 1px solid black;
    }
    td {
      user-select: none;
      font-size: 12px;
      &.multi-value:not(.disabled):hover {
        cursor: pointer;
        box-shadow: inset 0 0 0 2px lighten($infostat-interface-blue, 15);
      }
      &.disabled {
        background-color: $disabled-color;
      }
      &.invalid {
        background-color: $invalid-color;
      }
      &.selected {
        box-shadow: inset 0 0 0 2px lighten($infostat-interface-blue, 15);
      }
      &:not(.editing).selected {
        background-color: lighten($infostat-interface-blue, 65);
      }

      &.new-category {
        background-color: $warning-yellow-bg;
      }

      .select-cell {
        min-width: 200px;
        padding: 1px 0;
        padding-left: $size-lg;
        .ms-Dropdown-title,
        .ms-Dropdown-caretDownWrapper {
          font-size: 12px;
          font-family: monospace;
          height: 16px;
          line-height: 16px;
        }
        .ms-ComboBox,
        .ms-Dropdown {
          font-size: 12px;
          font-family: monospace;
          height: 100%;
        }
      }

      .ms-TextField-fieldGroup {
        height: 100%;
        border: none;
        padding: $size-xs;
        background-color: transparent;
        outline: none;
        .ms-TextField-field {
          font-family: monospace;
          font-size: 12px;
          padding: 0;
          outline: none;
        }
      }
    }
  }

  table.compact {
    td {
      padding: 0 $size-xs;
      white-space: nowrap;
    }
  }
}
