@import "@fluentui/react/dist/sass/References";

@import "../lib/style/variables";

$active-width: css-min(750px, 90vw);

$passive-width: 400px;
$passive-width-home-search: css-min(620px, 90vw);
$active-width-home-search: $passive-width-home-search;
$search-results-margin: 0px;
$active-width-results: css-min(#{750px - $search-results-margin}, 89vw);
$active-width-results-home-search: css-min(
  calc(#{$active-width-home-search} - 0px),
  89vw
);

$border-radius-home-search: 20px;
$box-shadow-home-search: 0 1px 6px 0 $grey6;

@keyframes shadow {
  0% {
    box-shadow: 0 1px 7px 1px $infostat-interface-blue;
  }
  50% {
    box-shadow: 0 1px 2px 1px lighten($infostat-interface-blue, 10);
  }
  100% {
    box-shadow: 0 1px 7px 1px $infostat-interface-blue;
  }
}

.infostat-search-box {
  &.home-search {
    width: $passive-width-home-search;
    margin-right: 0; // No margin needed unless in a card
    .ms-SearchBox {
      box-shadow: none;
      border-width: 1px;
      border-radius: $border-radius-home-search;
      border-color: $input-border-color-soft;
      z-index: 40;
      height: 46px;
      font-size: 22px;
      border-color: $grey7;
      &::after {
        border: unset;
      }
      &:hover {
        box-shadow: $box-shadow-home-search;
      }
    }
    .ms-SearchBox-clearButton,
    .ms-SearchBox-clearButton > button {
      border-top-right-radius: $border-radius-home-search;
    }
    &.show-results-box {
      .ms-SearchBox {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        clip-path: inset(-20px -20px 0px -20px);
      }
    }
    &.active {
      width: $passive-width-home-search;
      .ms-SearchBox {
        padding-left: $size-md;
        box-shadow: $box-shadow-home-search;
        animation: unset;
      }

      .search-results {
        background: white;
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid $grey7;
        box-shadow: 0px -3px 6px 0px $grey6, 0px 3px 3px 0px $grey7;
        width: $passive-width-home-search;
        max-height: 75vh;
        &.no-results {
          border-bottom-left-radius: $border-radius-home-search;
          border-bottom-right-radius: $border-radius-home-search;
          padding-left: $size-md;
          padding-bottom: $size-md;
        }
      }
    }

    .bar .bp4-icon {
      svg {
        margin-top: 4px;
        height: 16px;
        width: 16px;
        path {
          fill: $grey4;
        }
      }
    }
  }

  width: css-min($passive-width, 95%);
  position: relative;

  .bar {
    display: flex;
    > div {
      flex-grow: 1;
    }
  }

  transition-property: width;
  transition-duration: 0.2s;
  margin-right: $size-md;

  .hide-button {
    margin-left: $size-xs;
  }

  &.active {
    width: $active-width;

    .ms-SearchBox::after {
      border: unset;
    }

    .ms-SearchBox {
      padding-left: $size-md;
      z-index: 40;
      box-shadow: 0 1px 7px 1px $infostat-interface-blue;
      // Animate box-shadow on initial load
      animation: shadow 0.3s 1;
    }

    .search-results {
      margin: 0;
      width: $active-width-results;

      background: white;
      border-top: none;
      box-shadow: 0px -3px 6px 0px $infostat-interface-blue,
        0px 3px 5px 0px $infostat-interface-blue;
      max-height: 75vh;
      &.no-results {
        border-bottom-left-radius: $border-radius-home-search;
        border-bottom-right-radius: $border-radius-home-search;
        padding-left: $size-md;
        padding-bottom: $size-md;
      }
    }
  }

  &.show-results-box {
    .ms-SearchBox {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      clip-path: inset(-20px -20px 0px -20px);
      border-bottom: none;
    }
    .ms-SearchBox::after {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: unset;
    }
  }

  &:not(.active) {
    .ms-SearchBox-clearButton {
      display: none;
    }
  }

  &.btn-size .ms-SearchBox {
    height: $std-button-height;
  }

  .ms-SearchBox {
    border-color: $input-border-color-soft;
    border-width: 0;
    border-radius: 20px;
    box-shadow: 0 1px 5px 1px $grey5;
    height: 36px;

    input::placeholder {
      color: $grey4;
    }

    .ms-SearchBox-icon {
      fill: $grey3;
      padding-top: 1px;
    }
  }

  .search-results {
    transition-property: width;
    transition-duration: 0.2s;
    width: $passive-width;
    box-sizing: border-box;

    z-index: 30;

    box-shadow: 1px 1px 2px 1px $grey7;
    position: absolute;
    background-color: white;
    overflow: auto;
    max-height: 500px;

    .no-results {
      font-size: 110%;
      font-style: italic;
      padding: $size-sm $size-sm;
    }

    .category-line-small {
      font-size: 95%;
      color: $grey2;
    }

    .result-item {
      &:not(:first-child) {
        border-top: 1px solid $grey7;
      }
      .hit-info {
        font-weight: bold;
      }
      .infostat-logo {
        max-width: 18px;
      }
      &:hover {
        cursor: pointer;
        background-color: $infostat-interface-blue-text-highlight;
      }
      &.focused {
        background-color: $list-select-bg-color;
      }
      display: flex;
      justify-content: space-between;

      padding: $size-sm $size-sm;
    }
  }
}

// TODO: improve search on mobile, starting with the below
@media screen and (max-width: $breakpoint-mobile) {
  // .infostat-search-box {
  //   &.home-search {
  //     &.active {
  //       z-index: 190;
  //       top: 0;
  //       position: fixed;
  //       width: 100%;
  //       .search-results {
  //         position: absolute;
  //         width: 100%;
  //       }

  //       .ms-SearchBox {
  //         border-radius: 0;
  //         box-shadow: none;
  //       }
  //     }
  //   }
  // }
}
