@import "../../../../../lib/style/variables";
@import "../card_general/card_container_shared";

.python-card {
  @include data-selections;

  .grow {
    flex-grow: 1;
  }

  .python-card-analysis {
    min-height: 200px;

    .file-downloads-panel {
      margin: 0 $size-md $size-md $size-md;

      .downloadable-file {
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:first-child) {
          margin-left: $size-md;
        }
      }
    }
    .console-output {
      .console-header {
        background-color: $infostat-interface-blue;
        color: white;
        padding: $size-sm $size-sm $size-xs $size-sm;
      }
      background-color: $bg-color-soft;

      .ck-editor__editable {
        background-color: $light-grey4;
        border: none;
        max-height: 1000px;
        overflow-y: auto;
      }
    }

    .cm-editor {
      border-top: 1px solid $grey5;
      border-bottom: 1px solid $grey5;
    }
    .cm-focused {
      outline: none;
    }
    .foldout-panel-templates {
      margin: 0;
    }

    .pivot-item {
      margin: 0 $size-md $size-sm $size-md;
    }

    .column-inserter {
      display: flex;
      align-items: flex-end;
      column-gap: $size-md;
      .ms-Dropdown {
        width: 350px;
      }
    }

    .insert-column {
      label {
        margin-right: $size-xs;
      }
      display: flex;
      flex-wrap: wrap;
      row-gap: $size-sm;
    }

    .template-selection {
      .templates-list {
        margin-top: $size-md;
        display: flex;
        flex-wrap: wrap;
        max-height: 500px;
        overflow: auto;
        .template {
          padding: $size-sm;
          border: 1px solid $grey5;
          border-radius: $border-radius-sm;
          p {
            margin: 0;
          }
          p:last-of-type {
            margin-bottom: $size-sm;
          }

          &:not(:last-child) {
            margin-right: $size-md;
          }
        }
      }

      .item {
        display: inline-block;
        width: $text-input-default-width;
        margin-right: $size-md;
      }
    }
  }

  .create-dataframe {
    padding-top: $size-md;
    padding-bottom: $size-md;

    .micro-measure-selection,
    .micro-breakdowns-selection {
      display: flex;
      align-items: flex-end;

      .item {
        display: inline-block;
        width: $text-input-default-width;
        margin-right: $size-md;
      }
    }

    .checkboxes {
      display: flex;
      .checkbox {
        margin-right: $size-md;
      }
    }

    .projections {
      .projection-entry,
      .add-projection {
        .ms-TextField {
          flex-grow: 1;
        }
        margin-bottom: $size-xs;
        display: flex;
        align-items: flex-end;
      }
    }

    .expression-assignment-symbol {
      margin-bottom: $size-sm;
      font-size: 20px;
    }

    .column-submit {
      display: flex;
      align-items: flex-end;
      column-gap: $size-sm;
    }

    .dataframe-table-container {
      max-height: 300px;
      overflow: auto;
      tbody td {
        font-family: monospace;
      }
    }
  }
}
