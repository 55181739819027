@import "../lib/style/variables";
@import "../lib/style/utility";

.App {
  // background: url("../assets/infostat-banner-750x450.png");
  // background-repeat: no-repeat;
  // background-size: cover;

  @media screen {
    height: 100vh;
    background-color: $main-bg-color;
  }
}

#main {
  @media screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.print-only {
  @media print {
    display: block;
  }
  @media screen {
    display: none;
  }
}

details > summary {
  cursor: pointer;
}

// Driver.js
.driver-overlay {
  z-index: 1000001 !important;
}

.infostat-tutorial-popover {
  .driver-popover-navigation-btns {
    margin-left: $size-md;
  }
  .driver-popover-next-btn,
  .driver-popover-prev-btn {
    padding: $size-sm $size-lg;
    text-shadow: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Selawik", sans-serif;
  }

  .driver-popover-next-btn,
  .driver-popover-next-btn:focus {
    background-color: $infostat-interface-blue;
    color: white;
  }
  .driver-popover-next-btn:hover {
    background-color: darken($infostat-interface-blue, 10%);
  }
}
