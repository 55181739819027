@import "../../../../../../lib/style/variables";

.geo-micro-color-dropdown {
  .no-color {
    margin-left: 5px; // To match MS color block components
    display: flex;
    align-items: center;
    &:hover,
    label:hover {
      cursor: pointer;
    }
    .no-color-box {
      display: inline-block;
      height: 30px;
      width: 30px;
      border: 1px solid grey;
      margin-right: $size-sm;
    }
  }
}

.micro-settings-modal {
  .local-z-range {
    display: flex;
    align-items: center;
  }

  .color-scheme-row {
    &:not(:last-child) {
      margin-bottom: $size-md;
    }
  }

  .current-colors-geo-micro {
    border-collapse: collapse;
    td:not(:first-child),
    th:not(:first-child) {
      padding-left: $size-md;
    }
    td:first-child {
      padding-right: $size-md;
    }

    .opacity-slider {
      min-width: 200px;
    }
  }

  .color-scheme {
    // display: inline-flex;
    padding: $size-sm;
    &.selected {
      border: 2px solid black;
    }
    &:not(.selected) {
      &:hover {
        cursor: pointer;
      }
      border: 1px solid $grey7;
    }
  }

  .custom-colors {
    .color-dropdown-container {
      display: flex;
      align-items: center;
      margin-bottom: $size-md;

      label {
        width: 100px;
      }
    }
  }

  .buttons-footer {
    display: flex;
    justify-content: flex-end;
  }

  .color-scheme-container {
    height: 20px;
  }
}
