@import "../../../../lib/style/variables";

#manage-aliases {
  .alias-line:not(:last-child) {
    margin-bottom: $size-sm;
  }
  .canonical-measure {
    margin-bottom: $size-md;
    p {
      margin: 0;
    }
    display: flex;
    // justify-content: space-between;
    align-items: flex-end;
  }
}
