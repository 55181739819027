@import "../../../../../lib/style/variables";

.create-card-embed-modal {
  .indent {
    margin-left: $size-lg;
  }
  .element {
    margin-bottom: $size-sm;
  }
}
