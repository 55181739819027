@import "../../../../../lib/style/variables";

.add-variable-dialog {
  .add-user-defined-value {
    .grow {
      flex-grow: 1;
    }
    display: flex;
    align-items: flex-end;
  }

  .formula {
    display: flex;

    .value-selector {
      min-width: 250px;
    }
    .value-selector:not(:first-child) {
      margin-left: $size-sm;
    }
    .value-selector:not(:last-child) {
      margin-right: $size-sm;
    }
  }
}
