@import "@fluentui/react/dist/sass/References";

@import "../lib/style/variables";

.infostat-multi-search-box {
  position: relative;
  &.narrow {
    width: 220px;
    .search-results {
      width: 219px;
    }
  }

  .ms-SearchBox .ms-SearchBox-icon {
    padding-top: 1px;
  }

  .search-results {
    box-shadow: 1px 1px 2px 1px $grey7;
    z-index: 30;
    position: absolute;
    background-color: white;
    overflow: auto;
    max-height: 500px;

    .result-item {
      display: flex;

      padding-top: $size-sm;
      &:last-child {
        padding-bottom: $size-sm;
      }

      margin: 0 $size-sm;
    }
  }
}
