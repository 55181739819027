@import "../lib/style/variables";

$light-text-color: white;

#data-admin-indicator,
#data-admin-indicator-prod {
  @media print {
    display: none;
  }
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;
  width: 100%;
  .draft-data-toggle {
    display: inline-flex;
    margin-left: $size-sm;
    label {
      font-weight: bold;
    }
  }
}
#data-admin-indicator-prod {
  background-color: lighten(blue, 25);
}
#data-admin-indicator {
  background-color: lighten($primary-red, 15);
}

.navbar-help-menu-container {
  .custom-menu-item {
    margin-left: 4px; // Hard-coded to match MS menu items
  }
}

.navbar-settings-menu-header {
  .row {
    display: flex;
    align-items: center;
    & > :first-child {
      margin-right: $size-sm;
    }
    color: $text-color-subdued;
  }
  border-bottom: 1px solid $grey7;
}

#nav-bar {
  @media print {
    display: none;
  }
  .save-button {
    fill: black;
  }
  display: flex;
  justify-content: center;
  font-size: 120%;

  box-shadow: 0 1px 2px $grey6;
  z-index: 100;
  background-color: darken($infostat-interface-blue, 2);
  color: $light-text-color;
  fill: $light-text-color;

  .nav-bar-inner {
    display: flex;
    max-width: $navbar-content-max-width;
    align-items: center;
    flex-grow: 1;
  }

  .nav-main {
    margin-left: $size-lg;
    flex-grow: 1;
    display: flex;
    align-items: center;

    .logo-container {
      flex-grow: 0;
      flex-shrink: 1;
      display: flex;
      align-content: center;
      margin: $size-sm $size-sm;
      margin-right: $size-lg;
    }

    .logo-container-custom {
      flex-grow: 0;
      flex-shrink: 1;
      display: flex;
      align-content: center;
      margin: $size-sm $size-sm;
      margin-right: $size-lg;
      & > img {
        max-width: 100px;
        max-height: 24px;
      }
    }

    margin-left: $size-lg;
    @media screen and (max-width: $screen-sm) {
      margin-left: 0;
      .logo-container.logo-big,
      .logo-container-custom.logo-big {
        display: none;
      }
    }
    @media screen and (min-width: ($screen-sm + 1)) {
      .logo-container.logo-small,
      .logo-container-custom.logo-small {
        display: none;
      }
    }

    svg {
      max-width: 100px;
      max-height: 24px;
    }
    &.packaged-doc svg,
    &.third-party-doc svg {
      max-width: 150px;
      max-height: 30px;
    }
  }

  .save-status {
    color: $grey8;
    margin-left: $size-md;
    font-family: $font-family-brand-regular;
    font-size: 80%;

    .saving-spinner {
      .label {
        margin-left: map-get($std-sizes, "sm");
      }
      display: flex;
    }
  }

  .help-action-button {
    font-size: 14px;
    &:not(.custom-branding) {
      color: $light-text-color;
    }
    background-color: transparent;
    border: none;
    font-weight: bold;
    svg {
      width: 18px;
      height: 18px;
    }
    @media screen and (max-width: $screen-sm) {
      .ms-Button-textContainer,
      .ms-Icon-placeHolder {
        display: none;
      }
    }
  }

  .nav-bar-group {
    display: flex;
  }

  .report-meta {
    .title-container {
      font-weight: bold;
      &.editable:hover {
        cursor: pointer;
      }
      display: flex;
      align-items: center;
      .title {
        margin-right: $size-sm;
      }
      .icon svg {
        width: 14px;
      }
    }
    display: flex;
    align-items: center;
    padding-left: $size-lg;
    border-left: 1px solid $grey8;
    font-size: 14px;
  }
}
