@import "../../../lib/style/variables";

$max-size: 300px;

.rearrange-dialog {
  overscroll-behavior: contain;
  .thumbnail-container {
    background-color: white;
    margin: 0 $size-lg;
    margin-bottom: $size-md;
    label {
      font-weight: bold;
      font-size: 12px;
    }

    .thumbnail-error,
    .chart-placeholder-thumbnail,
    .table-thumbnail,
    .python-thumbnail,
    .text-ck-thumbnail,
    .micro-thumbnail {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100px;
    }
    .thumbnail-error,
    .chart-placeholder-thumbnail,
    .thumbnail-img-wrapper,
    .table-thumbnail,
    .text-ck-thumbnail,
    .python-thumbnail,
    .micro-thumbnail {
      border: 1px solid $grey6;
      padding: $size-xs;

      img {
        object-fit: cover;
        width: 100%;
        max-width: $max-size;
      }
      max-height: $max-size;
      overflow: hidden;
    }

    &:not(:last-child) {
      margin-bottom: $size-md;
    }
  }
}
