@import "@fluentui/react/dist/sass/References";

@function css-function($function, $values...) {
  @return $function + unquote("(") + $values + unquote(")");
}

// CSS functions that are overridden by SASS
// SASS uses these to produce values at compile time,
// unlike the pure CSS variants.
@function css-min($values...) {
  @return css-function(min, $values);
}

@function css-max($values...) {
  @return css-function(max, $values);
}

@function css-clamp($values...) {
  @return css-function(clamp, $values);
}

@mixin box-shadow-light {
  box-shadow: rgba(100, 100, 100, 0.23) 0px 1px 2.6px 1px,
    rgba(100, 100, 100, 0.21) 0px 0.3px 0.9px 1px;
}

$breakpoint-mobile: 480px;
$breakpoint-big-screen: 1000px;

$font-family-ui-regular: "Segoe UI", "Selawik", -apple-system,
  BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
$font-family-brand-regular: "Circular-Regular";
$font-family-brand-medium: "Circular-Medium";

$font-family-card-text-default: "Circular-Regular";
$font-family-card-text-headers: "Circular-Medium";

$svg-fonts: Verdana, "DejaVu Sans", sans-serif;

// Brand colors
$primary-red: #e05263;
$dark-green: #1c5253;
$logo-green: #285e4b;
$dark-blue: #235789;

$infostat-primary-green: #276955;
$infostat-interface-blue: #5a81a7;

$infostat-interface-blue-bg: lighten($infostat-interface-blue, 35);
$infostat-interface-blue-text-highlight: lighten($infostat-interface-blue, 43);

$turqoise: #76c3cc;
$brown-light: #a66a5a;

// Utility colors

$intent-default-bg-color: rgba(138, 155, 168, 0.15);
$intent-warning-bg-color: rgb(255, 244, 206);
$intent-danger-bg-color: rgba(219, 55, 55, 0.15);
$intent-primary-bg-color: rgba(19, 124, 189, 0.15);
$intent-success-bg-color: rgba(15, 153, 96, 0.15);

$folder-orange: #e8a427;
$color-help-blue: rgb(100, 149, 237);

$button-hover-grey: rgb(244, 244, 244);
$button-depressed-grey: rgb(234, 234, 234);
$button-bg-disabled: rgb(244, 244, 244);

$link-color: rgb(0, 0, 238);

$grey1: #111;
$grey2: #222;
$grey3: #444;
$grey4: #666;
$grey5: #888;
$grey6: #aaa;
$grey7: #ccc;
$grey8: #eee;

$light-grey1: #ced9e0;
$light-grey2: #d8e1e8;
$light-grey3: #e1e8ed;
$light-grey4: #ebf1f5;
$light-grey5: #f5f8fa;

$std-sizes: (
  "xs": 0.2rem,
  "sm": 0.4rem,
  "md": 0.7rem,
  "lg": 1.2rem,
  "xl": 2.4rem,
);
$size-xs: map-get(
  $map: $std-sizes,
  $key: "xs",
);
$size-sm: map-get(
  $map: $std-sizes,
  $key: "sm",
);
$size-md: map-get(
  $map: $std-sizes,
  $key: "md",
);
$size-lg: map-get(
  $map: $std-sizes,
  $key: "lg",
);
$size-xl: map-get(
  $map: $std-sizes,
  $key: "xl",
);

$border-radius-sm: 4px;
$border-radius-lg: 8px;

$text-input-default-width: 250px;
$text-input-narrow-width: 200px;

$text-color-subdued: $grey5;
$text-color-disabled: $grey7;
$text-color-soft-black: $grey3;
$bg-color-soft: #faf9f8;
$input-border-color-soft: $grey7;

// Calculate max width of card based on 4 input fields, with a one-side margin each,
// and some extra space for the card corner buttons
// as well as for content margins
// Min: Never go larger than a regular laptop screen.
$content-max-width: css-min(
  100vw,
  1366px,
  calc(0.7rem * 4 + #{$text-input-default-width} * 4 + 80px)
);
// Same as above, but allow extra size for the scrollbar
$content-max-width-with-scrollbar: css-min(
  100vw,
  1366px,
  calc(0.7rem * 4 + #{$text-input-default-width} * 4 + 80px + 20px)
);
$modal-narrow-max-width: css-min(100vw, 500px);
$navbar-content-max-width: calc(#{$content-max-width} - #{$size-sm} * 2);

$card-min-height: 200px;
$chart-max-width: 600px;
$read-only-chart-card-max-width: $content-max-width;

// Note that this is a duplication of FluentUI color definitions.
// FluentUI exports color variables for Sass but these are deprecated as they have
// moved to CSS-in-JS.
$warning-yellow-bg: rgb(255, 244, 206);
$soft-black: rgb(50, 49, 48);

$main-bg-color: #edebe9;

$soft-grey: rgb(243, 242, 241);
$tool-surface-bg-color: rgb(243, 242, 241);
$tool-surface-bg-color-hover: darken($tool-surface-bg-color, 10%);
$table-separator-color: $soft-grey;

/* Font sizes */
$table-font-size: 0.8rem;
$font-size-p-s: 0.8rem;
$font-size-p-m: 0.9rem;

$font-size-menu-item: 0.9rem;
$font-size-menu-header: 1.1rem;

$btn-lg-height: 40px;
$btn-std-height: 32px;
$btn-sm-height: 24px;

/* Fluent UI related code */
$std-button-height: 40px;
$list-select-bg-color: rgb(243, 242, 241);

$condensed-field-label-size: 12px;

// Screen sizes
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
