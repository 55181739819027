@import "../../../lib/style/variables.scss";

@page {
  margin: 2cm 2cm;
}

.Stats {
  .toolbars {
    z-index: 15;
    @media print {
      display: none;
    }
  }
  .page {
    flex-grow: 1;
    display: flex;
  }

  .page-scrollable {
    flex-grow: 1;
    overflow-y: auto;
    @media print {
      overflow: visible;
    }
    &.no-frames {
      background-color: white;
      .CardWithTab {
        margin-top: 0;
        margin-bottom: 0;
        .card-main {
          border: none;
        }
      }
    }
  }

  .menu-container {
    .pivot-menu {
      .ms-Pivot-icon {
        vertical-align: sub;
      }
    }
  }

  .no-card-placeholder {
    margin: $size-xl;
  }

  .pivot-placeholder-container {
    box-sizing: border-box;
    padding: $size-md;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-radius: $border-radius-lg;
    align-items: center;
    background-color: $bg-color-soft;
    .inner-area {
      flex-grow: 1;
    }
    .bp4-icon svg {
      fill: rgba($grey4, 0.5);
    }
  }
}
