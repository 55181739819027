@import "../../../../../lib/style/variables";

@mixin measure-source-info {
  h2 {
    font-size: 20px;
    margin-bottom: $size-sm;
  }
  h3 {
    font-size: 14px;
    margin-bottom: $size-xs;
  }
  p {
    margin-bottom: $size-md;
    margin-top: 0;
  }
  details summary {
    cursor: pointer;
  }
}
