@import "../../../../../lib/style/variables";

.third-party-micro-card-settings {
  margin-top: $size-md;
  section:not(:last-child) {
    margin-right: $size-lg;
  }
  p {
    margin-top: 0;
  }
  .element {
    margin-bottom: $size-sm;
  }
}
