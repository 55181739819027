@import "../lib/style/variables";

#app-messages-bar {
  position: fixed;
  z-index: 50;
  margin: 0;
  &.active {
    z-index: 1000001; // Higher than modals
    margin: map-get($map: $std-sizes, $key: md);
  }
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .app-message {
    .text {
      font-size: 14px;
    }
    z-index: 100;
    box-shadow: 1px 2px 2px rgba(200, 200, 200, 0.5);
    max-width: $content-max-width;
    &:not(:last-child) {
      margin-bottom: map-get($map: $std-sizes, $key: md);
    }
  }
}
