@import "../../../../../lib/style/variables";
@import "../card_general/card_container_shared";

.embedded-card-container {
  @include common-card-container-styles;
  @include common-card-content-styles;
  $element-margin: css-max(2vw, $size-md);

  .stats-geo-and-output-container {
    display: flex;
    flex-direction: column;
    &.editing .stats-output-viewer {
      border-left: none;
      min-height: 0;
      min-width: 0;
    }

    &.no-controls {
      overflow: visible;
    }
    &.no-controls .stats-output-viewer {
      border-top: none;
      overflow: visible;
      .tab-content {
        overflow: visible;
      }
    }

    // Embedded table output does not need the extra padding, a sliver of margin is enough.
    .stats-output-viewer .dataset-tables.tab-content {
      padding: 0;
      width: 100vw;
      overflow-x: auto;
      .dataset-table-container {
        padding: $size-sm;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .stats-output-viewer .tab-content-with-toolbar .tab-content {
      max-height: unset;
      .svg-chart-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  .simple-geo-display {
    display: flex;
    margin: $element-margin;
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .time-select {
    margin: calc(#{$element-margin} / 2) $element-margin;
    margin-top: 0;
    &:first-child {
      padding-top: calc(#{$element-margin} / 2);
    }
    padding: 0;
  }

  .breakdowns-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: $element-margin;
    padding-top: css-min(#{$element-margin}, $size-sm);
    .item {
      flex-grow: 1;
      // flex-basis: 100%;
      max-width: $text-input-default-width;
      // max-width: css-max(calc(95vw / 4), $text-input-default-width);
      &:not(:last-child) {
        margin-right: $size-sm;
      }
    }
  }
}
