.buttons-footer {
  display: flex;

  .right {
    display: flex;
    margin-left: auto;
  }
  .left {
    margin-right: auto;
  }
}
