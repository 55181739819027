@import "../../../../../lib/style/variables";

$indent-size: 25px;

.geo-selector {
  padding: map-get($std-sizes, "lg");
  padding-top: map-get($std-sizes, "sm");
  padding-right: 0;
  // padding: 0 $size-xs;
  .section-title {
    margin-bottom: $size-sm;
  }

  .tree .tree {
    padding-left: $indent-size;
  }

  .select-all {
    margin-bottom: $size-md;
    padding-left: $indent-size;
    label > span {
      margin-left: 0;
    }
  }

  .checkbox-and-label {
    &:not(:last-child) {
      margin-bottom: $size-sm;
    }
    display: flex;
  }

  .tree {
    .title {
      margin-bottom: $size-sm;
      display: flex;
      .label-and-expansion {
        flex-grow: 1;
        margin-right: $size-md;
      }
      .expansion-icon {
        margin: 0 $size-md;
      }

      &.expandable {
        .label-and-expansion,
        .expansion-icon {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .label {
        padding-top: 2px;
      }
    }
  }
}
