@import "../lib/style/variables";

.custom-dropdown {
  .custom-dropdown-container {
    z-index: 101;
    background-color: white;
    padding: map-get($map: $std-sizes, $key: md);
    box-shadow: rgba(0, 0, 0, 0.23) 0px 1.6px 3.6px 0px,
      rgba(0, 0, 0, 0.21) 0px 0.3px 0.9px 0px;
  }

  .button {
    margin-right: map-get($map: $std-sizes, $key: sm);
    padding: map-get($map: $std-sizes, $key: xs)
      map-get($map: $std-sizes, $key: sm);

    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $grey5;
    border-radius: 1px;

    &:hover {
      cursor: pointer;
      background-color: $button-hover-grey;
    }

    &.open {
      background-color: $button-depressed-grey;
    }

    .icon {
      margin-left: map-get($map: $std-sizes, $key: xs);
    }
  }

  &.no-margin .button {
    margin-right: 0;
  }
}
