@import "../../lib/style/variables";

.text-button {
  border: none;
  padding: 0;
  background-color: transparent;
  text-decoration: underline;
  color: $link-color;

  &:hover {
    cursor: pointer;
  }
}
