@import "../../../../../lib/style/variables";

.data-fields-with-editing {
  .editable-field {
    display: flex;
    align-items: flex-end;

    .checkbox + .checkbox {
      margin-left: $size-sm;
    }

    &.date {
      .infostat-button {
        // WORKAROUND: extra spacing in Fluent UI component that is hard to control
        margin-bottom: 5px;
      }
    }
    & > div {
      flex-grow: 1;
    }
  }
}
