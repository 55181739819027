@import "../../../../../lib/style/variables";
@import "../card_general/card_container_shared";

$map-loading-overlay-color: rgba($grey8, 0.7);
$map-disabled-overlay-color: rgba($grey8, 0.6);
$border-style: 1px solid $grey6;

$stripes-band-width: 2px;
$stripes-background: repeating-linear-gradient(
  -45deg,
  $map-disabled-overlay-color,
  $map-disabled-overlay-color $stripes-band-width,
  darken($map-disabled-overlay-color, 10) $stripes-band-width,
  darken($map-disabled-overlay-color, 10) $stripes-band-width * 2
);

.micro-card-content {
  @include common-card-content-styles;
}

.micro-card-img-packaged-doc {
  display: flex;
  justify-content: stretch;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.infostat-micro {
  // Rendered before the map is loaded
  // When everything is loaded this class is removed from the card
  &.invisible-micro-card {
    position: fixed;
    left: 0;
    right: 0;
    visibility: hidden;
    // Since this card has a fixed position it doesn't automatically respect available content width,
    // which may result in too wide Mapbox maps. A Mapbox map wider than available width will then
    // be cut off and appear as showing the wrong region
    max-width: $content-max-width;
  }

  // The SVG container is centered in read-only mode, but also needs to be
  // centered in non-read-only mode in third party documents.
  .third-party-doc .svg-chart-container {
    margin: auto;
  }

  .map-outer-container {
    position: relative;
  }

  .micro-output-container {
    position: relative;
    overflow: hidden;
  }

  .selection-error {
    margin-left: $size-lg;
    margin-right: $size-lg;
  }

  .micro-viewer-disabled-overlay {
    z-index: 90;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $stripes-background;
  }

  .box-select .mapboxgl-canvas-container {
    cursor: crosshair;
  }

  &.read-only {
    .mapboxgl-canvas-container {
      cursor: default;
    }

    .mapboxgl-ctrl-geocoder,
    .mapbox-tool-panel > .mapboxgl-ctrl {
      display: none;
    }
  }

  .mapbox-container {
    height: 700px;
    &.fullscreen {
      height: 100vh;
    }
  }

  .menu-container-micro:not(.no-controls-above-toolbar) {
    border-top: $border-style;
  }

  .feature-info-container {
    position: absolute;
    z-index: 99;
  }

  .micro-selection-panel,
  .micro-results-panel {
    font-family: $font-family-brand-regular;
  }

  .micro-selection-panel {
    max-height: 600px;
    overflow: auto;
  }

  .micro-selection-panel,
  .mapboxgl-ctrl-geocoder {
    box-sizing: border-box;
    width: 250px;
  }

  .with-loading-state-overlay {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: $map-loading-overlay-color;
  }

  .micro-results-loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-container {
      padding: $size-sm $size-lg;
      background-color: $light-grey4;
      opacity: 0.8;
      border-radius: $border-radius-sm;
    }

    background: $stripes-background;
  }

  .boxdraw {
    background: rgba($infostat-interface-blue, 0.2);
    border: 2px solid $infostat-interface-blue;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
