@import "../../../../../../lib/style/variables";

$bg-color: rgba(white, 1);

.title-control {
  h2 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    margin-top: $size-sm;
  }
  padding: $size-sm $size-md;
  min-width: 250px;
}
