@import "../../../../../lib/style/variables";

#computation-settings-dialog {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .ms-Dropdown-container {
    max-width: 200px;
  }
  .preview {
    overflow: auto;
    flex-grow: 1;
  }
}
