@import "../../../../../lib/style/variables";

.data-load-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50%;
  width: 80%;
  margin: $size-lg auto $size-lg auto;
  padding: $size-sm;

  .message-container {
    border-radius: $border-radius-lg;
    padding: $size-lg;
    background-color: $light-grey4;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      margin-top: $size-md;
    }

    p {
      font-size: medium;
      margin: $size-xs;
    }
  }
}
